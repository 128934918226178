<!-- Event snippet for Formulario de Whatsapp conversion page
In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->

  

<div class="popup-box chat-popup {{classe}}">
    <div class="row m-0" *ngIf="typeChat == 'home' ">
      <div class="col-12 popup-head py-3">
    
        <div class="row">
          <div class="col-12">
            <h2 class="mb-0">Olá!</h2>
            <p class="mb-2">Só precisamos de algumas informações básicas e vamos te direcionar para o 
                <strong>Whatsapp</strong>.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0" *ngIf="typeChat == 'home' ">
      <div class="col-12">



  <form [formGroup]="form" (submit)="send()">
    



    <section class="pb-0" style="padding:0px; margin-top:15px; ">
        <div class="container" data-aos="fade-up" data-aos-delay="500">
          <div class="row">
           
            <div class="col-12">
              <div class="form-group black-font" style="margin-bottom: 0.5rem;" >
                <label for="input-group-example" class="black-font" i18n="contact|">
                  Nome
                  <small class="text-danger">*</small>
                </label>
                <input type="text"  formControlName="name" class="form-control  black-font" id="input-group-example"
                  aria-describedby="input-group-example" placeholder="{{placeholders?.name}}"
                  [ngClass]="{ 'is-invalid': form.controls['name'].touched && !form.controls['name'].valid }">
                <div class="invalid-feedback" i18n="contact|">Você deve preencher este campo corretamente</div>
              </div>
            </div>
        
            <div class="col-12">
              <div class="form-group black-font" style="margin-bottom: 0.5rem;">
                <label for="input-group-example" class="black-font" >
                    Email
                    <small class="text-danger">*</small>
                  </label>
                <input type="text"   formControlName="email" class="form-control  black-font" id="input-group-example"
                  aria-describedby="input-group-example" placeholder="{{placeholders?.email}}"
                  [ngClass]="{ 'is-invalid': form.controls['email'].touched && !form.controls['email'].valid }">
                <div class="invalid-feedback" i18n="contact|">Você deve preencher este campo corretamente</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group black-font" style="margin-bottom: 0.5rem;"  >
                <label for="input-group-example" class="black-font" >
                    Telefone    
                    <small class="text-danger">*</small>
                  </label>
                <input type="text"   formControlName="phone" class="form-control  black-font" id="input-group-example"
                  aria-describedby="input-group-example" placeholder="{{placeholders?.phone}}"
                  [ngClass]="{ 'is-invalid': form.controls['phone'].touched && !form.controls['phone'].valid }">
                <div class="invalid-feedback" i18n="contact|">Você deve preencher este campo corretamente</div>
              </div>
            </div>


            <div class="col-12">
                <button class="btn  btn-success btn-block mb-5 mt-3" type="submit" [disabled]="!form.valid"
                *ngIf="!success && !error">
                <span *ngIf="!loading">
                    <i  class="fa fa-whatsapp" style="margin-right:10px; "></i>
                    <span >Iniciar conversa</span>   
                </span>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
              </button>
            </div>
          </div>
        </div>
      </section>






</form>



     
      </div>
    </div>
   
    
   
  </div>
  <div (click)='btnChatType()' [className]="btnChat ? 'round hollow badge badge-pill badge-primary zap-btn' : ' round hollow badge badge-pill badge-secondary box-shadow-gray'" *ngIf="language == 'br'">
    <div id="addClass" [hidden]='!btnChat'>
      
      <i style="margin-top:8px; font-size:2rem" class="fa fa-whatsapp"></i>
     
    </div>
    <div id="removeClass" [hidden]='btnChat'>
      X
      <i class="fe fe-x"></i>
    </div>
  </div>


    

    <!-- <a href="https://wa.me/5511991341871?text=Olá!%20Gostaria%20de%20um%20orçamento%20para%20o%20meu%20app" style="position:fixed;width:65px;height:65px;bottom:40px;right:40px;background-color:#25d366;color:#FFF;border-radius:50px;text-align:center;font-size:30px;box-shadow: 1px 1px 2px #888;
    z-index:1000;" target="_blank">
  <i style="margin-top:16px" class="fa fa-whatsapp"></i>
  </a>  -->
  
  
  