import { Routes } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { PortfolioComponent } from '../pages/portfolio/portfolio.component';
import { ServicesComponent } from '../pages/services/services.component';
import { TeamComponent } from '../pages/team/team.component';
import { BlogComponent } from '../pages/blog/blog.component';
import { CalculatorComponent } from '../pages/calculator/calculator.component';
import { TermsComponent } from '../pages/terms/terms.component';
import { BlogPostDetailComponent } from '../pages/blog-post-detail/blog-post-detail.component';
import { BlogPostsComponent } from '../pages/blog-posts/blog-posts.component';
import { TermsClientComponent } from '../pages/terms-client/terms-client.component';
import { FreeEvaluationComponent } from '../pages/free-evaluation/free-evaluation.component';

export const AppRoutes: Routes = [

    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'pages/contact', component: ContactComponent },
    { path: 'pages/portfolio', component: PortfolioComponent },
    { path: 'pages/services', component: ServicesComponent },
    { path: 'pages/team', component: TeamComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog/posts/category/:category', component: BlogPostsComponent },
    { path: 'blog/posts/search/:search', component: BlogPostsComponent },



    { path: 'blog/:slug', component: BlogPostDetailComponent },

    { path: 'pages/calculator', component: CalculatorComponent },
    { path: 'pages/free-evaluation', component: FreeEvaluationComponent },

    { path: 'pages/terms', component: TermsComponent },
    { path: 'client-terms/:id', component: TermsClientComponent },
    // { path: '**', component: NotFoundComponent, }

];
