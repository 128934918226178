import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from './routes/app.routes';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from './pages/contact/contact.component';

import { CountUpModule } from 'ngx-countup';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ServicesComponent } from './pages/services/services.component';
import { TeamComponent } from './pages/team/team.component';
import { ModalNavbarLinksComponent } from './components/modal-navbar-links/modal-navbar-links.component';
import { FlickityModule } from 'ngx-flickity';
import { CalculatorComponent } from './pages/calculator/calculator.component';
import { ContactCtaComponent } from './components/contact-cta/contact-cta.component';
import { BlogComponent } from './pages/blog/blog.component';
import { TermsComponent } from './pages/terms/terms.component';
import { BlogPostDetailComponent } from './pages/blog-post-detail/blog-post-detail.component';
import { BlogPostsComponent } from './pages/blog-posts/blog-posts.component';
import { BlogSearchBarComponent } from './components/blog-search-bar/blog-search-bar.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { FreeEvaluationComponent } from './pages/free-evaluation/free-evaluation.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    ContactComponent,
    PortfolioComponent,
    ServicesComponent,
    TeamComponent,
    ModalNavbarLinksComponent,
    CalculatorComponent,
    ContactCtaComponent,
    BlogComponent,
    TermsComponent,
    BlogPostDetailComponent,
    BlogPostsComponent,
    BlogSearchBarComponent,
    WhatsappComponent,
    FreeEvaluationComponent
  ],
  imports: [
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy', anchorScrolling: 'enabled' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CountUpModule,
    FlickityModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
