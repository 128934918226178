<app-navbar></app-navbar>

    <app-blog-search-bar></app-blog-search-bar>














<div class="main-content">













  <section *ngIf=posts.length>
    <div class="container">






      <div class="row">
        <div class="col">
          <h3 class="h2">
            Destaque
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 col-lg-8 d-flex">
          <a href="{{ 'blog/' + featured.slug }}"
            class="card card-body overlay border-0 text-light mb-md-0 justify-content-end">
            <div class="position-relative">
              <!-- <a href="{{ 'blog/posts/category/' + featured.categories[0] }}" class="mb-3"> -->
              <span class="badge badge-light mb-2">
                {{ featured.category_text }}
              </span>
              <!-- </a> -->
              <h2 class="mb-0">
                {{ featured.title.rendered }}
              </h2>
            </div>
            <img src="{{ featured.better_featured_image?.source_url }}" alt="Image" class="rounded bg-image">
          </a>
        </div>
        <div class="col-md-5 col-lg-4">
          <ul class="list-unstyled list-articles">
            <li *ngFor="let m of miniFeature" class="row row-tight">
              <a href="{{ 'blog/' + m.slug }}" class="col-3 col-md-4">
                <img src="{{ m.better_featured_image?.source_url }}" alt="Image" class="rounded">
              </a>
              <div class="col d-flex flex-column justify-content-start">
                <small class="mb-1 text-muted">Em {{ m.date | date: 'd/MM/yyyy' }}</small>
                <a href="{{ 'blog/' + m.slug }}">
                  <h6 class="mb-1">
                    {{ m.title.rendered }}
                  </h6>
                </a>

                <div class="d-flex text-small">
                  <a href="{{ 'blog/posts/category/' + m.categories[0] }}" class="text-muted">{{ m.category_text }}</a>

                  <!-- <a href="{{ 'blog/' + m.slug }}">{{ m.category_text }}</a> -->

                </div>
                <div>

                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-0">
    <div class="container">
      <div class="row">
        <div class="col">
          <h3 class="h2">
            Últimas notícicas
          </h3>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-8 col-lg-9">

          <div *ngFor="let p of posts">
            <div class="card card-article-wide flex-md-row no-gutters">
              <a href="{{ 'blog/' + p.slug }}" class="col-md-4">
                <img src="{{ p.better_featured_image.source_url }}" alt="Image" class="card-img-top">
              </a>
              <div class="card-body d-flex flex-column col-auto p-4">
                <div class="d-flex justify-content-between mb-2">
                  <div class="text-small">
                    <span class="text-muted">Publicado em {{ p.date | date: 'd/MM/yyyy' }}</span>
                  </div>
                </div>
                <a href="{{ 'blog/' + p.slug }}" class="flex-grow-1">
                  <h3>{{ p.title.rendered }}</h3>
                </a>
                <div class="d-flex align-items-center mt-3">

                  <a href="{{ 'blog/posts/category/' + p.categories[0] }}">
                    <span class="badge badge-light ml-0">
                      {{ p.category_text }}
                    </span>
                  </a>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-3 d-none d-md-block">
          <div class="sticky-top pb-3">
            <img src="assets/img/banner-ad.png" alt="Image" class="rounded shadow-3d">
            <!-- <a href="#" class="bg-primary-3-alt rounded p-4 d-flex align-items-center justify-content-center min-vh-30">
              <span class="text-small text-primary-3">Advertisement</span>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
<app-footer></app-footer>