<app-navbar></app-navbar>
<div class="main-content pt-6 min-vh-100" style="background-color: #212529;">

  <section class="bg-dark header-inner p-0">
    <div class="container py-0 layer-2">
      <div class="row text-light">
        <div class="col d-flex justify-content-center">
          <a class="navbar-brand fade-page" routerLink="">
            <img src="assets/img/logo-white.svg" alt="Phurshell">
            <!-- <h1>{{ step }}</h1>
             -->
          </a>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container">


      <!-- TITLE -->
      <div class="row justify-content-center text-center text-light" *ngIf="step == 0">
        <div class="col-12 col-lg-3 mb-4">
          <img src="assets/img/pillars/pillar4.svg" alt="Image" class="aos-init aos-animate" data-aos="fade-left">
        </div>
        <div class="col-12">
          <h2 class="h1" i18n="calculator|">
            Gestão de TI: como melhorar produtividade, qualidade e inovação
          </h2>
          <div class="lead mb-4" >

            <span i18n="calculator|">Vamos entender melhor o seu cenário</span>

            <br>

            <span i18n="calculator|">e sugerir uma solução!</span>

          </div>
          <button class="btn btn-primary btn-lg" (click)="advance(i,a)" i18n="calculator|">
            Iniciar avaliação gratuita
          </button>
        </div>
      </div>

      <!-- PERGUNTAS E RESPOTAS DE CONSULTORIA -->
      <div class="row justify-content-center" *ngIf="step > 0">
        <div class="col-12" [hidden]="i != step - 1" *ngFor="let c of content; let i = index">
          <h2 class="h1 text-light text-center mb-3">
            {{step}}. {{c.q}}
          </h2>
          <div class="d-sm-flex justify-content-center mb-3">
              <button class="btn btn-lg btn-link text-light" (click)="backButton()">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-light" data-src="assets/img/icons/theme/code/left-circle.svg">
                    <title>Icon For Left-circle</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                        <path d="M6.96323356,15.1775211 C6.62849853,15.5122561 6.08578582,15.5122561 5.75105079,15.1775211 C5.41631576,14.842786 5.41631576,14.3000733 5.75105079,13.9653383 L10.8939067,8.82248234 C11.2184029,8.49798619 11.7409054,8.4866328 12.0791905,8.79672747 L17.2220465,13.5110121 C17.5710056,13.8308912 17.5945795,14.3730917 17.2747004,14.7220508 C16.9548212,15.0710098 16.4126207,15.0945838 16.0636617,14.7747046 L11.5257773,10.6149773 L6.96323356,15.1775211 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.500001, 12.000001) scale(-1, 1) rotate(-270.000000) translate(-11.500001, -12.000001) "></path>
                    </g>
                </svg>
                <span i18n="calculator|">
                  Voltar
                </span>
              </button>
            </div>
          <div class="row justify-content-center">
            <div class="col-md-12 col-xl-12 d-flex" (click)="advance(i,a)" *ngFor="let a of c.a">
              <a class="card card-body hover-shadow-3d">
                <h3 class="m-0">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/navigation/check.svg">
                    <title>Icon For Check</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
                        <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "></path>
                    </g>
                </svg> {{a.text}}
                </h3>
                <!-- <h5 class="text-muted">
                  {{a.price}}
                </h5> -->
              </a>
            </div>
          </div>
          <p class="lead text-warning text-center mt-3">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
              height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-warning"
              data-src="assets/img/icons/theme/code/info-circle.svg">
              <title>Icon For Info-circle</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                <rect fill="#000000" x="
                11" y="10" width="2" height="7" rx="1"></rect>
                <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"></rect>
              </g>
            </svg>

            <span i18n="calculator|">Selecione uma das opções acima para prosseguir.</span>

            
          </p>
        </div>
      </div>


       <div class="row justify-content-center text-light" *ngIf="step == content.length + 1">
        <div class="col-xl-8 col-md-10">
          <h2 class="text-center">
            Está quase acabando!
          </h2>
          <p class="lead text-center mb-4">
            Preencha as informações abaixo para continuar.
          </p>
          <form [formGroup]="form" (submit)="submit()">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="name">
                    Seu nome
                  </label>
                  <input id="name" type="text" class="form-control form-control-lg" placeholder="Insira seu nome"
                  [ngClass]="{ 'is-invalid': form.controls['name'].touched && !form.controls['name'].valid }"
                  formControlName="name"
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">
                    Seu email
                  </label>
                  <input id="email" type="email" class="form-control form-control-lg" placeholder="seu@email.com"
                  [ngClass]="{ 'is-invalid': form.controls['email'].touched && !form.controls['email'].valid }"
                  formControlName="email"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="phone">
                    Seu telefone
                  </label>
                  <input id="phone" type="text" class="form-control form-control-lg" placeholder="Insira seu telefone"
                  [ngClass]="{ 'is-invalid': form.controls['phone'].touched && !form.controls['phone'].valid }"
                  formControlName="phone"
                  >
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="phone">
                    Seu cargo
                  </label>
                  <input id="phone" type="text" class="form-control form-control-lg" placeholder="Insira seu cargo"
                  [ngClass]="{ 'is-invalid': form.controls['position'].touched && !form.controls['position'].valid }"
                  formControlName="position">
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="phone">
                    Setor de mercado
                  </label>
                  <input id="phone" type="text" class="form-control form-control-lg" placeholder="Insira o setor"
                  [ngClass]="{ 'is-invalid': form.controls['sector'].touched && !form.controls['sector'].valid }"
                  formControlName="sector"
                  >
                </div>
              </div>

              <div class="col-md-12" *ngFor="let c of leadContent; let i = index">
           
                <p style="color: rgba(255, 255, 255, 0.75);font-size: 0.875rem;font-weight: 700;">{{c.q}}</p>
                  
         
                <div class="row justify-content-center">
                  <div class="col-md-3 col-xl-3 d-flex" (click)="selectLeadOption(i,a,j)" *ngFor="let a of c.a; let j = index">
                    <a class="card card-body justify-content-center align-items-center " style="padding: 1rem" 
                    
                    [ngClass]="{'selected-option': a.selected,
                    'hover-shadow-3d' : !a.selected
                
                  }"
                    >
                      <h3 class="text-center" style="color:black;font-size:1rem;margin:0">
                        {{a.text}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>


          

            </div>
            <div class="form-group text-center mt-5">
              <button class="btn btn-lg btn-primary btn-block mb-2" (click)="advance()" [disabled]="!form.valid">
                Continuar
              </button>
            </div>
          </form>
        </div>
      </div> 

      
      <!-- RESULTS -->
      <div class="row justify-content-center text-center text-light" *ngIf="step == content.length + 2">
        <div class="col-12 col-lg-3 mb-4">
          <img src="assets/img/pillars/pillar3.svg" alt="Image" class="aos-init aos-animate" data-aos="fade-left">
        </div>
        <div class="col-12" >
          <h3 i18n="calculator|">
            Relatório de avaliação gratuita
          </h3>
          <!-- <h1>

              Apoio - {{ suggested.apoio }}<br>
              Apps - {{ suggested.apps }}<br>
              MVP - {{ suggested.mvp }}<br>
              Mentoria - {{ suggested.mentoria }}<br>
            
          </h1> -->
          <div class="row" style="padding-top:30px; padding-bottom:30px" *ngIf="reportLoading">
            <div class="col-12 mt-3 mb-3">
               
                <h2>Gerando relatório gratuito</h2>
                <div class="progress">
                  <div class="progress-bar bg-success" role="progressbar" style="width: {{reportLoadPerc}}%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{reportLoadPerc}}%</div>
                </div>
        
            </div>
          </div>

          <div class="row text-left mb-5 mt-5" *ngIf="!reportLoading">
            <div class="col-12">
              <h4>Introdução</h4>
              <p>
                Nós fizemos uma análise baseada no perfil da sua empresa, informado na avaliação, e concluímos quais são as melhores opções para impulsionar a sua área de tecnologia e qualidade de seus produtos digitais. Importante dizer que esperamos que você entre em contato conosco para esclarecer dúvidas, conhecer melhor nossos serviços e, assim, chegaremos juntos à melhor proposta para sua empresa.
              </p>
            </div>


            <div class="col-12" *ngIf="suggested.apoio">
              <h4>Apoio contínuo</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>

            <div class="col-12" *ngIf="suggested.apps">
              <h4>Aplicativos móveis e web</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>

            <div class="col-12" *ngIf="suggested.mvp">
              <h4>MVP e Provas de Conceito</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>

            <div class="col-12" *ngIf="suggested.mentoria">
              <h4>Mentoria em tecnologia</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>


          </div>




          <div class="lead mb-4"  i18n="calculator|">
            Entendemos que essas são algumas possibilidades, mas de acordo com seu perfil, queremos oferecer uma <strong>consultoria gratuita</strong> com nosso especialista. Em um papo de 45 minutos, ele vai entender melhor o seu cenário e orientar sobre as possíveis soluções para seu negócio.
          </div>
          <div class="d-sm-flex justify-content-center my-2 my-md-4">
            <button class="btn btn-lg btn-outline-secondary text-light mx-sm-2 mb-3 mb-sm-0" (click)="refresh()">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-light"
                data-src="assets/img/icons/theme/general/update.svg">
                <title>Icon For Update</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M8.43296491,7.17429118 L9.40782327,7.85689436 C9.49616631,7.91875282 9.56214077,8.00751728 9.5959027,8.10994332 C9.68235021,8.37220548 9.53982427,8.65489052 9.27756211,8.74133803 L5.89079566,9.85769242 C5.84469033,9.87288977 5.79661753,9.8812917 5.74809064,9.88263369 C5.4720538,9.8902674 5.24209339,9.67268366 5.23445968,9.39664682 L5.13610134,5.83998177 C5.13313425,5.73269078 5.16477113,5.62729274 5.22633424,5.53937151 C5.384723,5.31316892 5.69649589,5.25819495 5.92269848,5.4165837 L6.72910242,5.98123382 C8.16546398,4.72182424 10.0239806,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 L6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C10.6885336,6 9.44767246,6.42282109 8.43296491,7.17429118 Z"
                    fill="#000000" fill-rule="nonzero"></path>
                </g>
              </svg>
              <span  i18n="calculator|">
                Recomeçar o estudo
              </span>
            </button>
            <a (click)="endStep()" class="btn btn-lg btn-primary mx-sm-2 mb-3 mb-sm-0">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
                data-src="assets/img/icons/theme/communication/sending-mail.svg">
                <title>Icon For Sending mail</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z"
                    fill="#000000" opacity="0.3"></path>
                  <path
                    d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z"
                    fill="#000000"></path>
                </g>
              </svg>
              <span  i18n="calculator|">Agende sua consultoria gratuita</span>
            </a>
          </div>
        </div>
      </div>




      



    </div>
  </section>

</div>

<app-footer></app-footer>