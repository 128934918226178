import { Component, OnInit } from '@angular/core';
import { CountUpOptions } from 'countup.js';

declare var $: any

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})

export class PortfolioComponent implements OnInit {

  endValApps = undefined;
  endValYears = undefined;
  counterApps: CountUpOptions;
  counterYears: CountUpOptions;

  constructor() { }

  ngOnInit() {

    this.flickity();
    this.useOptions();

    setTimeout(() => {
      this.applyEndVal()
    }, 400);

  }

  applyEndVal() {
    this.endValApps = 100;
    this.endValYears = 10;
  }

  useOptions() {
    this.counterApps = {
      duration: 5,
      useEasing: true,
      suffix: '&nbsp;apps'
    };
    this.counterYears = {
      duration: 5,
      useEasing: true,
      suffix: '&nbsp;'
    };
  }

  flickity() {
		$(document).ready(function () {
			$('.main-carousel').flickity({ 
				selectedAttraction: 0.2,
				friction: 1.5,
				wrapAround: true,
				imagesLoaded: true,
				cellAlign: 'center',
				fullscreen: false,
				pageDots: false,
        autoPlay: 5000,
        pauseAutoPlayOnHover: true
			});
		})
	}





}
