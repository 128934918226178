import { Component, OnInit, OnDestroy } from '@angular/core';
import { PathRoutes } from '../../routes/routersUrl';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms'
import { RequestService } from '../../services/request.service'
import { LOCALE_ID, Inject } from '@angular/core';
declare var gtag;

declare var $: any;

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})

export class CalculatorComponent implements OnInit, OnDestroy {

  PathRoutes = PathRoutes

  summedValues = []

  loading = false

  success = false

  step = 0
  value = 0;
  form: FormGroup

  language

  multiplier = 0.3; //para brincar com o preço fim da calculadora. 1 seria 100%...0.6 seria 60%

  content = [

    {
      q: "Qual tipo de aplicativo você está procurando?",
      userAnswer: "",
      a: [
        {
          text: "Aplicativo para iPhone",
          price: "$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 100000,
        },
        {
          text: "Aplicativo para Android",
          price: "$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 100000,
        },
        {
          text: "Aplicativo para iPhone e Android",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 120000,
        },
      ]

    },
    {
      q: "Que nível de qualidade de construção você está procurando?",
      userAnswer: "",
      a: [
        {
          text: "Interações básicas, performance baixa",
          price: "$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 5000,
        },
        {
          text: "Interações customizadas, alta performance",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 15000,
        }
      ]
    },
    {
      q: "Qual design você deseja que o seu aplicativo tenha?",
      userAnswer: "",
      a: [
        {
          text: "Sem customização",
          price: "$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Interface básica",
          price: "$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 5000,
        },
        {
          text: "Interface customizada",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 20000,
        },
      ]

    },
    {
      q: "Como será o retorno financeiro do seu aplicativo?",
      userAnswer: "",
      a: [
        {
          text: "Aplicativo gratuito",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Aplicativo pago para baixar",
          price: "$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 2000,
        },
        {
          text: "Compras dentro do aplicativo",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 15000,
        }
      ]

    },
    {
      q: "Seu aplicativo precisa de cadastro e login?",
      userAnswer: "",
      a: [
        {
          text: "Não",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Sim, apenas com email",
          price: "$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 5000,
        },
        {
          text: "Sim, com redes sociais e email",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 15000,
        }
      ]

    },
    {
      q: "Seu aplicativo precisa de integração com alguma plataforma ou site?",
      userAnswer: "",
      a: [
        {
          text: "Não",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Sim",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 15000,
        }
      ]
    },
    {
      q: "Os usuários devem ter seu perfil próprio?",
      userAnswer: "",
      a: [
        {
          text: "Não",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Sim",
          price: "$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 5000,
        }
      ]
    },
    {
      q: "Seu aplicativo vai enviar notificações para os usuários?",
      userAnswer: "",
      a: [
        {
          text: "Não",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Sim",
          price: "$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 15000,
        }
      ]
    },
    {
      q: "Seu aplicativo vai precisar usar a localização dos seus usuários?",
      userAnswer: "",
      a: [
        {
          text: "Não",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Sim",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 12000,
        }
      ]
    },
    {
      q: "Seu aplicativo necessita de um painel administrativo?",
      userAnswer: "",
      a: [
        {
          text: "Não",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Sim",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 20000,
        },
      ]

    },
    {
      q: "Quantas línguas você deseja que seu aplicativo tenha?",
      userAnswer: "",
      a: [
        {
          text: "Uma única língua",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Bilingue",
          price: "$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 30000,
        },
        {
          text: "Multiplos idiomas",
          price: "$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 50000,
        },
      ]
    },
    {
      q: "Em que estágio se encontra o seu projeto?",
      userAnswer: "",
      a: [
        {
          text: "Apenas tenho ideia ou esboço",
          price: "Sem custo",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Aplicativo em desenvolvimento",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
        {
          text: "Aplicativo já está pronto",
          price: "$$$",
          image: "https://placehold.it/350x350",
          value: this.multiplier * 0,
        },
      ]
    }
  ]

  constructor(private api: RequestService, private formBuilder: FormBuilder, @Inject(LOCALE_ID) protected localeId: string) { 

    this.language = localeId

  }


  advance(i = -1, a =
    {
      text: "",
      price: "",
      image: "",
      value: 0
    }
  ) {
    this.step++
  
    this.summedValues.push(typeof a.value == 'number' ? a.value : 0 )
    this.value += typeof a.value == 'number' ? a.value : 0

    if(a.value > 0){
        this.content[i].userAnswer = a.text
    }

    console.log('adding', this.value)

    if (isNaN(this.value) || typeof this.value == 'undefined') {
      this.refresh()
    }

  }

  backButton(){
    var remove = this.summedValues.pop()

    if(typeof remove == 'number'){
        console.log('removing ',remove)
        this.value -= remove
        console.log(this.value)
        this.step--;
    } else {
      console.log('remove var is ',typeof remove)
    }

    if (isNaN(this.value) || typeof this.value == 'undefined') {
      this.refresh()
    }
   

  }

  ngOnInit() {
    this.setupForm()

    $("#hideNav").hide()
    $("#hideFooter").hide()
  }



  setupForm() {
    this.form = this.formBuilder.group({
      'language':[this.language],
     'name': [null, Validators.compose([Validators.required])],
     'email': [null, Validators.compose([Validators.required, Validators.email])],
     'phone': [null, Validators.compose([Validators.required])]

    })
   }
  ngOnDestroy() {
    $("#hideNav").show()
    $("#hideFooter").show()
  }

  refresh(): void {
    window.location.reload();
  }

  getFormattedPrice(price: any, cifrao = true) {
    price = parseFloat(price)
    var type = cifrao ? 'currency' : 'decimal'


    switch(this.language){
      case 'br':
        return new Intl.NumberFormat('pt-BR', { style: type, currency: 'BRL' }).format(price)
        case 'pt':
        return new Intl.NumberFormat('pt-BR', { style: type, currency: 'BRL' }).format(price)
        case 'en':
        return new Intl.NumberFormat('en-US', { style: type, currency: 'USD' }).format(price)
    }

  }


  endStep() {
    this.step++
  }

  submit(){
    this.loading = true

    var obj = this.form.getRawValue()


    this.content.map((q)=>{
      obj[q.q] = q.userAnswer
    })

    obj.total = this.value

    console.log(obj)
    this.api.sendCalculator(obj).subscribe(res => {
          // Adwords Conversion
          gtag('event', 'conversion', {
          'send_to': 'AW-831945220/JvZ8CPncxN8DEIT02YwD'
        });
      this.loading = false
      this.success = res.status
    })
  }



}
