import { Component, OnInit, OnDestroy } from '@angular/core';
import { PathRoutes } from '../../routes/routersUrl';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms'
import { RequestService } from '../../services/request.service'
import { LOCALE_ID, Inject } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-free-evaluation',
  templateUrl: './free-evaluation.component.html',
  styleUrls: ['./free-evaluation.component.scss']
})

export class FreeEvaluationComponent implements OnInit, OnDestroy {

  PathRoutes = PathRoutes

  reportLoading = true
  reportLoadPerc = 0;

  suggested = {
    apoio: true,
    apps: true,
    mvp: true,
    mentoria: true
  }

  summedValues = []

  loading = false

  success = false

  step = 0; 
  value = 0;
  form: FormGroup

  language


  leadContent = [
    {
      q: "Quantas pessoas trabalham na sua empresa?",
      type: "multiple",
      userAnswer: "",
      a: [
        {
          text: "Até 10 pessoas",
          selected: false
        },
        {
          text: "de 10 a 49 pessoas",
          selected: false
        },
        {
          text: "de 50 a 99 pessoas",
          selected: false
        },
        {
          text: "100 ou mais pessoas",
          selected: false
        }
      ]

    },
    {
      q: "Qual faturamento anual da sua empresa?",
      type: "multiple",
      userAnswer: "",
      a: [
        {
          text: "Até R$1 milhões por ano",
          selected: false
        },
        {
          text: "Até R$10 milhões por ano",
          selected: false
        },
        {
          text: "Até R$50 milhões por ano",
          selected: false
        },
        {
          text: "Mais que R$50 milhões por ano",
          selected: false
        }
      ]

    },
  ]



  content = [

    {
      q: "Você já tem uma equipe de TI?",
      type: "multiple",
      userAnswer: "",
      a: [
        {
          text: "Sim, mas não consigo entregar todos os projetos.",
          suggestion: {
            apoio: true,
            apps: true,
            mvp: true,
            mentoria: true
          },
        },
        {
          text: "Sim e ela atende 100% dos meus projetos.",
          suggestion: {
            apoio: false,
            apps: false,
            mvp: false,
            mentoria: true
          },
        },
        {
          text: "Não, ainda não tenho um time de TI.",
          suggestion: {
            apoio: true,
            apps: false,
            mvp: false,
            mentoria: true
          },
        }
      ]

    },
    {
      q: "Sobre seus projetos de TI, o que melhor representa seu cenário atual?",
      type: "multiple",
      userAnswer: "",
      a: [
        {
          text: "Tenho muitos projetos para entregar. Preciso de ajuda para executar.",
          suggestion: {
            apoio: true,
            apps: true,
            mvp: true,
            mentoria: true
          },
        },
        {
          text: "Tenho projetos para entregar, mas preciso de ajuda para priorizar.",
          suggestion: {
            apoio: false,
            apps: false,
            mvp: true,
            mentoria: true
          },
        },
        {
          text: "Não tenho clareza dos projetos que preciso desenvolver ainda. Preciso de ajuda para definir projetos e escopos.",
          suggestion: {
            apoio: false,
            apps: false,
            mvp: true,
            mentoria: true
          },
        },
        {
          text: "Tenho projetos, todos bem organizados e priorizados.",
          suggestion: {
            apoio: false,
            apps: false,
            mvp: false,
            mentoria: true
          },
        }
      ]

    },
    {
      q: "Vamos falar de inovação? Você considera que:",
      type: "multiple",
      userAnswer: "",
      a: [
        {
          text: "Sua empresa está bem atualizada e inovando constantemente em seus produtos digitais.",
          suggestion: {
            apoio: false,
            apps: false,
            mvp: false,
            mentoria: true
          },
        },
        {
          text: "A empresa precisa se atualizar e criar rituais que favoreçam mais a inovação.",
          suggestion: {
            apoio: true,
            apps: true,
            mvp: true,
            mentoria: true
          },
        },
        {
          text: "Estamos em processo de evolução e caminhando para processos de inovação mais sólidos.",
          suggestion: {
            apoio: true,
            apps: true,
            mvp: true,
            mentoria: true
          },
        }
      ]

    },
    {
      q: "Dos itens abaixo, qual você acredita representar a maior dor/necessidade de sua área de TI hoje?",
      type: "multiple",
      userAnswer: "",
      a: [
        {
          text: "Tenho dificuldade de contratar e manter profissionais de TI.",
          suggestion: {
            apoio: true,
            apps: false,
            mvp: false,
            mentoria: true
          },
        },
        {
          text: "Sou gargalo. Por mais que aumente o time, não consigo dar vazão aos projetos.",
          suggestion: {
            apoio: true,
            apps: false,
            mvp: false,
            mentoria: true
          },
        },
        {
          text: "Tenho dificuldade em validar e priorizar os projetos.",
          suggestion: {
            apoio: false,
            apps: false,
            mvp: false,
            mentoria: true
          },
        }
        ,
        {
          text: "Sinto dificuldade em manter meu time atualizado e um processo de inovação constante na empresa.",
          suggestion: {
            apoio: true,
            apps: false,
            mvp: true,
            mentoria: true
          },
        }
      ]

    },
    {
      q: "Você tem uma demanda/projeto que você deve entregar com urgência e precisa de ajuda para fazer isso?",
      type: "multiple",
      userAnswer: "",
      a: [
        {
          text: "Sim, por isso busco soluções e alternativas de apoio.",
          suggestion: {
            apoio: true,
            apps: true,
            mvp: false,
            mentoria: false
          },
        },
        {
          text: "Não, estou tranquilo com relação aos meus projetos atuais.",
          suggestion: {
            apoio: false,
            apps: false,
            mvp: false,
            mentoria: true
          },
        },
        {
          text: "Tenho dificuldade em validar e priorizar os projetos.",
          suggestion: {
            apoio: false,
            apps: false,
            mvp: true,
            mentoria: true
          },
        }
        ,
        {
          text: "Sinto dificuldade em manter meu time atualizado e um processo de inovação constante na empresa.",
          suggestion: {
            apoio: true,
            apps: true,
            mvp: true,
            mentoria: true
          },
        }
      ]

    }
   
  ]

  constructor(private api: RequestService, private formBuilder: FormBuilder, @Inject(LOCALE_ID) protected localeId: string) { 

    this.language = localeId

  }


  advance(i = -1, a = null) {
    this.step++

    if(a){
      console.log(i,a,'alterando')
      if(a.suggestion.apoio) this.suggested.apoio = true
      if(a.suggestion.apps) this.suggested.apps = true
      if(a.suggestion.mvp) this.suggested.mvp = true
      if(a.suggestion.mentoria) this.suggested.mentoria = true
    } else {
      console.log(i,a,'não altera')
    }

    if(this.step == 6){
     this.loadAnimation()
    }

  }

  backButton(){
    this.step--;
    var remove = this.summedValues.pop()
    this.value -= remove
  }

  ngOnInit() {
    this.setupForm()

    $("#hideNav").hide()
    $("#hideFooter").hide()

  }

  loadAnimation() {

      setInterval(() => {
        if(this.reportLoadPerc == 100){
          this.reportLoading = false
        } else {
          this.reportLoadPerc += 5
        }
      }, 300)

  }

  setupForm() {
    this.form = this.formBuilder.group({
      'language':[this.language],
     'name': [null, Validators.compose([Validators.required])],
     'email': [null, Validators.compose([Validators.required, Validators.email])],
     'phone': [null, Validators.compose([Validators.required])],
     'position': [null, Validators.compose([Validators.required])],
     'sector': [null, Validators.compose([Validators.required])],
     'employees': [null, Validators.compose([Validators.required])],
     'revenue': [null, Validators.compose([Validators.required])],
    })
   }
  ngOnDestroy() {
    $("#hideNav").show()
    $("#hideFooter").show()
  }

  refresh(): void {
    window.location.reload();
  }

  getFormattedPrice(price: any, cifrao = true) {
    price = parseFloat(price)
    var type = cifrao ? 'currency' : 'decimal'


    switch(this.language){
      case 'br':
        return new Intl.NumberFormat('pt-BR', { style: type, currency: 'BRL' }).format(price)
        case 'pt':
        return new Intl.NumberFormat('pt-BR', { style: type, currency: 'BRL' }).format(price)
        case 'en':
        return new Intl.NumberFormat('en-US', { style: type, currency: 'USD' }).format(price)
    }

  }


  endStep() {
    this.step++
  }

  submit(){
    this.loading = true

    var obj = this.form.getRawValue()


    this.content.map((q)=>{
      obj[q.q] = q.userAnswer
    })

    obj.total = this.value

    console.log(obj)
    this.api.sendCalculator(obj).subscribe(res => {
      this.loading = false
      this.success = res.status
    })
  }



  selectLeadOption(i,a,j){

    this.leadContent[i].userAnswer = a.text

    if(!this.leadContent[i].a[j].selected){
        if(i == 0){
          this.form.controls['employees'].setValue(a.text)
        }
        if(i == 1){
          this.form.controls['revenue'].setValue(a.text)
        }
    }
   
    this.leadContent[i].a.map(w => {
      w.selected = false
    })

    this.leadContent[i].a[j].selected = !this.leadContent[i].a[j].selected

  }


}
