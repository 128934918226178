import { Component, OnInit } from '@angular/core';

import { BlogService } from '../../services/blog.service'

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  featured
  miniFeature = []
  posts = []
  categories = []

  constructor(private blog : BlogService) { }

  ngOnInit() {
    this.getCategories()
  }


  getPosts() {
    this.blog.posts().subscribe( (res) => {

      res.map((r,i) => {

        this.categories.map((c)=>{
          if(c.id == r.categories[0]) r.category_text = c.name
        })



        if(i==0) this.featured = r
        if(i>=1 && i <= 4) this.miniFeature.push(r)
        if(i>4) this.posts.push(r)


      })
      console.log(res,this.posts)
    })
  }

  getCategories() {
    this.blog.categories().subscribe( (res) => {

      this.categories = res
      this.getPosts()

    })
  }

}
