import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})


export class RequestService {

baseUrl = 'https://api.phurshell.com/api/';


private headers

  constructor(private http: HttpClient,
	private router: Router

	) {}

  	private defaultError: any = {status: false, message: 'Ocorreu um erro de servidor, tente novamente'}
  	private logoutBehavior = new BehaviorSubject<boolean>(false)
  	logoutWatcher = this.logoutBehavior.asObservable()



  sendZap(params): Observable<any> {
	return this.makeRequest('landing/leads/whatsapp',params,'post')
  }

    sendContact(params): Observable<any> {
      return this.makeRequest('landing/leads/contact',params,'post')
    }


    sendCalculator(params): Observable<any> {
      return this.makeRequest('landing/leads/calculator',params,'post')
    }



  	makeRequest(endPoint, params, type) {

		let token = localStorage.getItem('token') || '' // token valido por uma hora
		var apiKey = ''

		if(localStorage.getItem('user_session')){
			apiKey = JSON.parse(localStorage.getItem('user_session')).key //
		}


		switch(type){
			case "post":
				return this.http.post(this.baseUrl + endPoint, params, this.headers)
				.pipe(
					catchError(e => {
						this.checkLogout(e, endPoint)

						return new Observable((observer) => {
							of(this.defaultError)
						})
					})
				)

			case "get":
				return this.http.get<any>(this.baseUrl + endPoint, this.headers).pipe(
					catchError(e => {
						this.checkLogout(e, endPoint)

						return new Observable((observer) => {
							of(this.defaultError)
						})
					})
				);


			case "put":
				return this.http.put(this.baseUrl + endPoint, params, this.headers).pipe(
					catchError(e => {
						this.checkLogout(e, endPoint)
						return new Observable((observer) => {
							of(this.defaultError)
						})
					})
				);
		}
	}
	checkLogout(e, endPoint){
	// 	console.log('ERRO', e)

	// 	if(e.status === 403){
	// 		if(e.message === 'invalid_token'){
	// 			localStorage.removeItem('user_session')
	// 			this.router.navigate(['/'])
	// 		} else {
	// 			this.router.navigate([PathRoutes.Unauthorized])
	// 		}
	// 	}

	// 	if(e.status === 401){

	// 		if(e.error.block){
	// 			localStorage.removeItem('user_session')
	// 			this.router.navigate(['/'])
	// 		}
	// 		if(!e.error.status){
	// 			localStorage.removeItem('user_session')
	// 			this.router.navigate(['/'])
	// 		}

	// 	}

	}

	makeExternalRequest(endPoint, params, type){

		var headers = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json'
			})
		};

		console.log(headers);
		switch(type){
			case "post":
				return this.http.post(endPoint, params, headers);

			case "get":
				return this.http.get<any>(endPoint);

			case "put":
				 return this.http.put(endPoint, params,headers);
		}
	}
}
