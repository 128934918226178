
export const PathRoutes = {

    Home : '/pages/home',
    Contact : '/pages/contact',
    Portfolio : '/pages/portfolio',
    Services : '/pages/services',
    Team : '/pages/team',
    Blog : '/blog',
    BlogPosts : '/blog/posts',

    Calculator : '/pages/calculator',
    Terms : '/pages/terms'

}
