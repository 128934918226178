import { Component, OnInit } from '@angular/core'
import { RequestService } from '../../services/request.service'
import { FormBuilder, FormGroup, Validators, } from '@angular/forms'
import { LOCALE_ID, Inject } from '@angular/core';
import { PathRoutes } from '../../routes/routersUrl';

declare var gtag;



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  PathRoutes = PathRoutes

  form: FormGroup
  error = false
  success = false
  checkboxArray = []
  loading = false
  language

  placeholders

  constructor(private api: RequestService, private formBuilder: FormBuilder,@Inject(LOCALE_ID) protected localeId: string) {
    this.language = localeId

   }

  ngOnInit() {
    this.setupForm()

    
  }
 

  checkbox(val) {
    if(this.checkboxArray.includes(val)) {
      for( var i = 0; i < this.checkboxArray.length; i++) {
        if ( this.checkboxArray[i] === val) {
          this.checkboxArray.splice(i, 1);
        }
      }
    } else {
      this.checkboxArray.push(val)
    }
    this.form.patchValue({
      what_is_project: this.checkboxArray.join(', ')
     });
  }

  send() {
    this.loading = true
    console.log(this.form.getRawValue())
    this.api.sendContact(this.form.getRawValue()).subscribe(res => {
        // Adwords Conversion
        gtag('event', 'conversion', {
        'send_to': 'AW-831945220/7WNvCPzcxN8DEIT02YwD'
      });
      this.loading = false
      if(res.status){
        this.success = true
      } else {
        this.error = true
      }
    })
  }

  setupForm() {
    this.form = this.formBuilder.group({
     'language': [this.language],

     'name': [null, Validators.compose([Validators.required])],
     
     'company': [null, ""],
     'client_type': ["PJ", Validators.compose([Validators.required])],


     
     'email': [null, Validators.compose([Validators.required, Validators.email])],
     'phone': [null, Validators.compose([Validators.required])],
     'what_is_project': [null, Validators.compose([Validators.required])],
    //  'project_name': [null, ""],
     'project_description': [null, ""],
    //  'project_references': [null, ""],
    //  'schedule': [null, ""],
     'segment': [null, Validators.compose([Validators.required])],
     'company_size': [null, Validators.compose([Validators.required])],

     'budget': [null, ""],
     'how_know_phurshell': [null, Validators.compose([Validators.required])]
    })
    this.setupPlaceholders()

   }
   setupPlaceholders(){

    switch(this.language){
        case 'br':
        case 'pt':

          this.placeholders = {
            name : 'Digite seu nome',
            company : 'Digite o nome da sua empresa',
            email : 'Digite seu email',
            phone : 'Digite seu telefone',
            project_name : 'Digite o nome do projeto',
            project_description : 'Conte-nos mais sobre como podemos te ajudar.',
            project_references : 'Descreva suas referências'
          }
          break;
        case 'en':

          this.placeholders = {
            name : 'Your name',
            company : 'Your company\'s name',
            email : 'Your email',
            phone : 'Your phone number',
            project_name : 'Your project\'s name',
            project_description : 'Project description',
            project_references : 'Project references'
          }
          break;
          

    }
  }
}
