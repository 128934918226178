import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PathRoutes } from '../../routes/routersUrl';

declare var $:any

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  PathRoutes = PathRoutes

  constructor ( private activatedRoute: ActivatedRoute, private router: Router ) {

  }

  ngOnInit() {

  }

}
