import { Component,LOCALE_ID, Inject } from '@angular/core';
import * as AOS from 'aos';
import { PathRoutes } from './routes/routersUrl';
import { RequestService } from './services/request.service'
import { Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  PathRoutes = PathRoutes
  redirect_url = 'http://phurshell.com/'

  constructor(@Inject(LOCALE_ID) protected localeId: string, private api: RequestService, private titleService: Title) {

    console.log('current lang',this.localeId)

    if(this.localeId == 'en') this.setTitle('Phurshell | App Development Studio')

    // var country_redirect = localStorage.getItem('country_redirect')
    
    // if(!country_redirect){

    //   this.api.makeExternalRequest('http://www.geoplugin.net/json.gp', null,'get').subscribe(res => {
    //     console.log('IP Location')
    //     console.log(res.geoplugin_countryCode) // BR US PT

    //     if(!res) return false
  

    //     this.redirectCountry(res.geoplugin_countryCode)
      
  
    //   })

    // } else {
    //   console.log('already redirected')

    //   this.redirectCountry(country_redirect)
    // }

  }

  title = 'app';
  ngOnInit() {
    AOS.init();
  }

  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

  // redirectCountry(c){  
  //   switch(c){
  //       case 'BR':

  //       if(this.localeId != 'br'){
  //         window.location.replace(this.redirect_url + "br");
  //       }
   
  //       localStorage.setItem('country_redirect',c)
  //       break;

  //       case 'US':
  //       case 'CA':
  //       case 'GB':

  //         if(this.localeId != 'en'){
  //           window.location.replace(this.redirect_url + "en");
  //         }

  //       localStorage.setItem('country_redirect',c)
  //       break;

  //       case 'PT':

  //         if(this.localeId != 'pt'){
  //           window.location.replace(this.redirect_url + "pt");
  //         }

  //        localStorage.setItem('country_redirect',c)
  //       break;

  //       default:
  //       window.location.replace(this.redirect_url + "br");
  //       localStorage.setItem('country_redirect',c)

  //   }
  // }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}


