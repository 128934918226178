import { Component, OnInit, Renderer2, ElementRef, ViewChild} from '@angular/core';

import { FormBuilder, FormGroup, Validators, } from '@angular/forms'

import { RequestService } from '../../services/request.service'

import { LOCALE_ID, Inject } from '@angular/core';
declare var gtag;

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})

export class WhatsappComponent implements OnInit {


  form: FormGroup
 
  viewHeight: number;

  language


  loading = false

  typeChat: string = 'home';
  btnChat = true;
  classe: string;

  message: string;

  rollDown = false;

  btnChatType() {

    this.btnChat = !this.btnChat
    if (!this.btnChat) {
      this.classe = 'qnimate popup-box-on'
    } else {
      this.classe = 'qnimate'
    }
  }
  // checkSeeAll(typeChat) {

  //   if (typeChat == 'home') {
  //     this.ticketSelected = null
  //   }
  //   this.typeChat = typeChat;
  //   this.rollDown = false;
  // }

  constructor(private formBuilder: FormBuilder, private api: RequestService, @Inject(LOCALE_ID) protected localeId: string ) { 
    this.language = localeId

  }

  ngOnInit() {
    this.setupForm()


  }
  setupForm() {
    this.form = this.formBuilder.group({

     'name': [null, Validators.compose([Validators.required])],
     'email': [null, Validators.compose([Validators.required, Validators.email])],
     'phone': [null, Validators.compose([Validators.required])],
    
    })

   }
   
   send() {
    // Adwords Conversion
    gtag('event', 'conversion', {
      'send_to': 'AW-831945220/ttKyCPbcxN8DEIT02YwD'
    });

    this.loading = true
    console.log(this.form.getRawValue())

    let vals = this.form.getRawValue()
    this.api.sendZap(this.form.getRawValue()).subscribe(res => {


   

      var msg = "Olá, meu nome é " + vals.name + " e gostaria de um orçamento para o meu projeto."

      var newLocation = 'https://wa.me/5511991341871?text=' + msg
      // var newLocation = 'https://wa.me/5511975125587?text=' + msg



      const encoded  =  encodeURI(newLocation);

      setTimeout(()=>{  // coloquei esse set timeout por causa do evento do tag manager do google
        location.href = encoded;
        this.loading = false
      }, 1800);

    
    })
  }

}
