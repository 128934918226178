import { Component, OnInit } from '@angular/core';
import  Typed  from 'typed.js';
import { PathRoutes } from '../../routes/routersUrl';
import { LOCALE_ID, Inject } from '@angular/core';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  PathRoutes = PathRoutes

  language

  constructor(@Inject(LOCALE_ID) protected localeId: string) {

    this.language = localeId
   }

  ngOnInit() {

    var optionString = ['aplicativos modernos.', 'inovação digital.', 'design inteligente.', 'mentoria em tecnologia.', 'times ágeis.']

    // switch(this.localeId){
    //   case 'br':
    //     optionString = ['aplicativos modernos.', 'inovação digital.', 'design inteligente.', 'mentoria em tecnologia.', 'times ágeis.']
    //     break;
    //   case 'en':
    //     optionString = ['products.', 'apps.', 'websites.', 'webapps.']
    //     break;
    //   case 'pt':
    //     optionString = ['produtos.', 'aplicativos.', 'sites.', 'sistemas.']
    //     break;
    // }

    const options = {
      strings: optionString,
      typeSpeed: 65,
      backSpeed: 35,
      showCursor: false,
      loop: true
 };

 const typed = new Typed('.typed-element', options);


  }


}
