import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../services/blog.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PathRoutes } from '../../routes/routersUrl';


@Component({
  selector: 'app-blog-post-detail',
  templateUrl: './blog-post-detail.component.html',
  styleUrls: ['./blog-post-detail.component.scss']
})
export class BlogPostDetailComponent implements OnInit {

  PathRoutes = PathRoutes
  post
  slug

  constructor(private blog : BlogService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.slug = this.route.snapshot.paramMap.get('slug');

    this.getPost()
  }


  getPost() {
    this.blog.postDetail(this.slug).subscribe( (res) => {
      console.log(res)
      if(res.length)    this.post = res[0]


    })
  }


}

