<div class="modal p-0 aos-init aos-animate" id="modal" role="dialog" data-aos="fade-up" data-aos-delay="100">
  <div class="modal-dialog" role="document">
    <div class="modal-content bg-primary border-0 text-light min-vh-100">
      <div class="modal-body">
        <div class="navbar-container mb-5">
          <nav class="navbar navbar-expand-lg">
            <div class="container">
              <a class="navbar-brand fade-page" routerLink="">
                <img src="assets/img/logo-white.svg" alt="Phurshell">
              </a>
              <button class="navbar-toggler" type="button" data-toggle="modal" data-target="#modal">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-white"
                  data-src="assets/img/icons/theme/code/error-circle.svg">
                  <title>Icon For Error-circle</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                    <path
                      d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                      fill="#000000"></path>
                  </g>
                </svg>
              </button>
            </div>
          </nav>
        </div>
        <ul class="navbar-nav text-center mt-5">
          <li class="nav-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <a routerLink="/" i18n="modal_navbar_links|" data-dismiss="modal" class="nav-link h2" data-aos="fade-up" data-aos-delay="100">
              Home
            </a>
          </li>
          <li class="nav-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <a routerLink="{{ PathRoutes.Services }}" i18n="modal_navbar_links|" data-dismiss="modal" class="nav-link h2">
              O que fazemos?
            </a>
          </li>
          <li class="nav-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <a routerLink="{{ PathRoutes.Portfolio }}" i18n="modal_navbar_links|" data-dismiss="modal" class="nav-link h2">
              Projetos entregues
            </a>
          </li>
          <li class="nav-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <a routerLink="{{ PathRoutes.Team }}" i18n="modal_navbar_links|" data-dismiss="modal" class="nav-link h2">
              Quem somos?
            </a>
          </li>
          <li class="nav-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <a routerLink="{{ PathRoutes.Calculator }}" i18n="modal_navbar_links|" data-dismiss="modal" class="nav-link h2">
              Quanto custa um aplicativo?
            </a>
          </li>
          <li class="nav-item aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <a routerLink="{{ PathRoutes.Contact }}" i18n="modal_navbar_links|" data-dismiss="modal" class="nav-link h2">
              Entrar em contato
            </a>
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>