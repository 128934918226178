<app-navbar></app-navbar>
<app-blog-search-bar></app-blog-search-bar>

<div class="main-content">
  <section class="pt-4 pb-0">
    <div class="container">
      <div class="row">
        <div class="col">
          <p class="lead">
            {{title}}
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-4">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-lg-9">
          <div *ngFor="let p of posts">
            <div class="card card-article-wide flex-md-row no-gutters">
              <a href="{{ 'blog/' + p.slug }}" class="col-md-4">
                <img src="{{ p.better_featured_image.source_url }}" alt="Image" class="card-img-top">
              </a>
              <div class="card-body d-flex flex-column col-auto p-4">
                <div class="d-flex justify-content-between mb-2">
                  <div class="text-small">
                    <span class="text-muted">Publicado em {{ p.date | date: 'd/MM/yyyy' }}</span>
                  </div>
                </div>
                <a href="{{ 'blog/' + p.slug }}" class="flex-grow-1">
                  <h3>{{ p.title.rendered }}</h3>
                </a>
                <div class="d-flex align-items-center mt-3">
                  <a href="{{ 'blog/posts/category/' + p.categories[0] }}">
                    <span class="badge badge-light ml-0">
                      {{ p.category_text }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-3 d-none d-md-block">
          <div class="sticky-top pb-3">
            <img src="assets/img/banner-ad.png" alt="Image" class="rounded shadow-3d">
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>