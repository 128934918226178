<app-navbar></app-navbar>
<div class="main-content pt-6">
  <section>
    <div class="container">
      <div class="row">
        <div class="col">

          <h1 class="my-2">
            Termos de uso
          </h1>
          <div>Versão 1.0, Revisão 1</div>
          <hr>
          <article class="article">
            <p> Estes termos e condições gerais de uso aplicam-se aos serviços prestados pela pessoa jurídica Phurshell Desenvolvimento de Sites e Aplicativos Ltda - ME, devidamente registrada sob o CNPJ n. 26.689.276/0001­-12, com sede em: Rua Haddock Lobo, 131 - sala 609 CEP: 01414-001São Paulo-SP por meio do site Phurshell, com o seguinte endereço: phurshell.com.
            </p>
            <h4>
              1. Objetivo
            </h4>
            <p>
              O site Phurshell caracteriza-se pela prestação dos seguintes serviços, incluindo a venda à distância e por
              meio eletrônico de produtos e de serviços: Desenvolvimento de sites e aplicativos customizados.
            </p>
            <h4>
              2. Aceitação dos termos e condições gerais de uso
            </h4>
            <p>
              Todos aqueles que desejarem ter acesso aos serviços ofertados através do site Phurshell deverão,
              primeiramente, se informar sobre as regras que compõem o presente instrumento, as quais ficarão
              disponíveis para pronta e ampla consulta, em link direto no próprio site. Ao utilizar o site Phurshell, o
              usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob risco de aplicação das
              penalidades cabíveis. Antes de iniciar qualquer navegação no site, o usuário deverá cientificar-se de
              eventuais modificações ou atualizações ocorridas neste termos. Caso não concorde com quaisquer das regras
              aqui descritas, o usuário deve, imediatamente, abster-se de utilizar o serviço. Se for de seu interesse,
              poderá, ainda, entrar em contato com o serviço de atendimento ao cliente, para apresentar-lhe as suas
              ressalvas.
            </p>
            <h4>
              3. Navegação
            </h4>
            <p>
              O editor do site Phurshell se compromete a utilizar todas as soluções técnicas à sua disposição para
              permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. Entretanto, ele
              poderá, a qualquer momento, interromper, limitar ou suspender o acesso ao site ou a algumas de suas
              páginas, a fim de realizar atualizações, modificações de conteúdo ou qualquer outra ação julgada
              necessária para o seu bom funcionamento. Os presentes termos e condições gerais de uso e de compra e venda
              se aplicam a todas as extensões do site Phurshell em redes sociais ou em comunidades, tanto as já
              existentes, quanto aquelas ainda a serem implementadas.
            </p>
            <h4>
              4. Responsabilidades
            </h4>
            <p>
              O editor se responsabilizará pelos defeitos ou vícios encontrados nos serviços prestados pelo
              site Phurshell, desde que tenha lhes dado causa. Defeitos ou vícios técnicos ou operacionais originados no
              próprio sistema do usuário não serão de responsabilidade do editor. O editor responsabiliza-se apenas pelas informações que foram por ele diretamente divulgadas. Quaisquer informações incluídas pelos usuários, tais como em comentários e em perfis pessoais, serão de inteira
              responsabilidade dos próprios. O usuário é responsável, ainda: a) pela correta utilização do site e de seus serviços, prezando pela boa convivência, pelo respeito e pela cordialidade no relacionamento com os demais usuários; b) pelo cumprimento das regras contidas neste instrumento, bem como normas de Direito nacional e de Direito internacional; c) pela proteção dos dados de acesso à sua conta (login e senha).
              O editor não será responsável: a) pelas características intrínsecas da internet, principalmente relativas à confiabilidade e à
              procedência das informações circulando nesta rede; b) pelos conteúdos ou atividades ilícitas praticadas através de seu site.
            </p>
            <h4>
              5. Links extenos
            </h4>
            <p>
              O site Phurshell pode conter links externos redirigindo o usuário para outras páginas da internet, sobre os quais o editor não exerce controle. Apesar das verificações prévias e regulares realizadas pelo editor, ele se isenta de qualquer responsabilidade sobre o conteúdo encontrado nestes sites e serviços. Poderão ser incluídos links nas páginas e nos documentos do site Phurshell, desde que não sirvam para fins
              comerciais ou publicitários. Esta inclusão dependerá de autorização prévia do editor. Não será autorizada a inclusão de páginas que divulguem quaisquer tipos de informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.
            </p>
            <h4>
              6. Direitos autorais
            </h4>
            <p>
              A estrutura do site Phurshell, bem como os textos, os gráficos, as imagens, as fotografias, os sons, os
              vídeos e as demais aplicações informáticas que o compõem são de propriedade do editor e são protegidas
              pela legislação brasileira e internacional referente à propriedade intelectual. Qualquer representação, reprodução, adaptação ou exploração parcial ou total dos conteúdos, marcas e serviços propostos pelo site, por qualquer meio que seja, sem autorização prévia, expressa e escrita do editor, é estritamente vedada, podendo-se recorrer às medidas cíveis e penais cabíveis. Estão excluídos desta previsão apenas os elementos que estejam expressamente designados no site como livres de direitos autorais. O acesso não gera para o usuário qualquer direito de propriedade intelectual relativo a elementos do site, os quais restam sob a propriedade exclusiva do editor. É vedado ao usuário incluir no site dados que possam modificar o seu conteúdo ou sua aparência.
            </p>
            <h4>
              7. Política de privacidade
            </h4>
            <p>
              1. Dados pessoais
              Todos os dados pessoais fornecidos pelo usuário no momento de seu cadastroou que venham a ser solicitados
              posteriormente, a este título, permanecerão sigilosos e não serão repassados a quaisquer parceiros do
              site, exceto quando essas informações forem indispensáveis para o processamento de compras e de entregas.
              Em outras situações, o editor apenas poderá transmitir os dados pessoais a terceiros quando o usuário
              permiti-lo expressamente ou quando presentes as hipóteses legais previstas no Direito brasileiro, tais
              como a requisição judicial.
              É de responsabilidade do editor a garantia de confidencialidade dos dados pessoais fornecidos pelos
              usuários, devendo proteger o sitecontra tentativas de violações ou acessos clandestinos à sua base de
              dados.
            </p>
            <p>
              2. Dados de navegação
              O site recorre eventualmente às técnicas de "cookies", que lhe permitem analisar as estatísticas e as
              informações sobre a navegação do usuário. Podem ser fornecidos, por exemplo, dados sobre o dispositivo
              utilizado pelo usuário e o seu local de acesso. Esta coleta de informações busca melhorar a navegação,
              para o conforto do usuário, ao permitir apresentar-lhe serviços personalizados, de acordo com suas
              preferências.
              Estes dados de navegação poderão, ainda, ser compartilhados com eventuais parceiros do site, buscando o
              aprimoramento dos produtos e serviços ofertados ao usuário.
              O usuário poderá se opor ao registro de "cookies" pelo site, bastando desativar esta opção no seu próprio
              navegador ou aparelho. Por outro lado, a desativação deste registro poderá afetar a disponibilidade de
              algumas ferramentas e alguns serviços do site.
            </p>
          </article>
          <hr>
          <h6>
            Alterações
          </h6>
          <small>
            A presente versão dos termos e condições gerais de uso e de compra e venda foi atualizada pela última vez
              em: 27/10/2017.
              O editor se reserva o direito de modificar, a qualquer momento e sem qualquer aviso prévio, o site e os
              serviços, bem como as presentes normas, especialmente para adaptá-las às evoluções do site Phurshell, seja
              pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
              19. DO DIREITO APLICÁVEL E DO FORO
              Para a solução das controvérsias decorrentes do presente instrumento, será aplicado integralmente o
              Direito brasileiro, notadamente a sua legislação consumerista.
              Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede do editor do
              site.
          </small>
          <hr>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>