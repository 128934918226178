import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

import { Observable, throwError, of } from 'rxjs';
import {  HttpEvent} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class BlogService {
    blog_url = 'https://blogapi.phurshell.com/index.php/'

    constructor( private request: RequestService ) { }

  	posts(args = null) : Observable<any> {

      var url = this.blog_url + 'wp-json/wp/v2/posts?'

      if(args) url += args

  		return 	this.request.makeExternalRequest(url,[], 'get')
    }

    categories() : Observable<any> {
  		return 	this.request.makeExternalRequest(this.blog_url + 'wp-json/wp/v2/categories',[], 'get')
    }

    postDetail(slug) : Observable<any> {
  		return 	this.request.makeExternalRequest(this.blog_url + 'wp-json/wp/v2/posts?slug=' + slug,[], 'get')
    }


}
