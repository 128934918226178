<app-navbar></app-navbar>
<div class="main-content pt-6">

  <!-- JUMBOTRON -->
  <section>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 text-center text-lg-left mt-4 mb-lg-0">
          <h1 class="display-4 mb-0" data-aos="fade-up" data-aos-delay="100" i18n="services|jumbotron - title">
            O que fazemos?
          </h1>
          <div class="my-4" data-aos="fade-up" data-aos-delay="200">
            <p class="lead" i18n="services|jumbotron - description">
              Ajudamos você a elevar o seu negócio, alavancando o seu produto, através da consultoria e da arte do design. Caminhamos na linha fina entre produtos com experiências impressionantes e utilizáveis.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- APOIO CONTINUO -->
  <section class="" id="apoio-continuo">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-6 col-xl-5 px-md-6 px-lg-0">
          <div class="mb-3">
            <span class="badge badge-primary">
              Desenvolvimento
            </span>
            <h3 class="h1 mt-3" i18n="services|web - title">
              Apoio contínuo
            </h3>
            <p class="lead" i18n="services|web - description">
              Nós nos tornamos uma extensão da sua empresa com foco de desenvolvimento. Te ajudamos a manter seu produto existente e a constantemente desenvolver novos produtos e novas features. Também te ajudamos a manter tudo funcionando e corrigir eventuais bugs.
            </p>
          </div>
          <div class="row">
            <div class="col-6">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/communication/group.svg">
                <title>Icon For Group</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
                    <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                    <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path>
                </g>
            </svg>
              <div class="mt-2">
                <h5 i18n="services|web - sistem title">
                  Sua squad
                </h5>
              </div>
              <p i18n="services|web - sistem description">
                Te fornecemos uma squad completa de profissionais multidisciplinares.
              </p>
            </div>
            <div class="col-6">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/design/patch.svg">
                <title>Icon For Patch</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <path d="M11.2928932,5.63603897 L18.363961,12.7071068 L12,19.0710678 L4.92893219,12 L11.2928932,5.63603897 Z M8.46446609,11.2928932 C8.0739418,11.6834175 8.0739418,12.3165825 8.46446609,12.7071068 C8.85499039,13.0976311 9.48815536,13.0976311 9.87867966,12.7071068 C10.2692039,12.3165825 10.2692039,11.6834175 9.87867966,11.2928932 C9.48815536,10.9023689 8.85499039,10.9023689 8.46446609,11.2928932 Z M11.2928932,8.46446609 C10.9023689,8.85499039 10.9023689,9.48815536 11.2928932,9.87867966 C11.6834175,10.2692039 12.3165825,10.2692039 12.7071068,9.87867966 C13.0976311,9.48815536 13.0976311,8.85499039 12.7071068,8.46446609 C12.3165825,8.0739418 11.6834175,8.0739418 11.2928932,8.46446609 Z M11.2928932,14.1213203 C10.9023689,14.5118446 10.9023689,15.1450096 11.2928932,15.5355339 C11.6834175,15.9260582 12.3165825,15.9260582 12.7071068,15.5355339 C13.0976311,15.1450096 13.0976311,14.5118446 12.7071068,14.1213203 C12.3165825,13.7307961 11.6834175,13.7307961 11.2928932,14.1213203 Z M14.1213203,11.2928932 C13.7307961,11.6834175 13.7307961,12.3165825 14.1213203,12.7071068 C14.5118446,13.0976311 15.1450096,13.0976311 15.5355339,12.7071068 C15.9260582,12.3165825 15.9260582,11.6834175 15.5355339,11.2928932 C15.1450096,10.9023689 14.5118446,10.9023689 14.1213203,11.2928932 Z" fill="#000000"></path>
                    <path d="M10.5150629,20.4145579 C8.57369375,21.7007639 5.93228695,21.4886361 4.22182541,19.7781746 C2.51136387,18.0677131 2.2992361,15.4263063 3.58544211,13.4849371 L10.5150629,20.4145579 Z" fill="#000000" opacity="0.3"></path>
                    <path d="M12.7778303,4.29254889 C14.7191995,3.00634288 17.3606063,3.21847065 19.0710678,4.92893219 C20.7815294,6.63939373 20.9936571,9.28080053 19.7074511,11.2221697 L12.7778303,4.29254889 Z" fill="#000000" opacity="0.3"></path>
                </g>
            </svg>
              <div class="mt-2">
                <h5>
                  Manutenção
                </h5>
              </div>
              <p i18n="services|web - landing description">
                Te ajudamos a levantar e a corrigir bugs. Também te ajudamos a definir futuras melhorias.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-8 mt-4 mt-lg-0">
              <img src="assets/img/services-web.png" alt="Image" data-aos="fade-left" class="aos-init aos-animate">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!--  APPS -->
  <section class="" id="apps">
    <div class="container">
      <div class="row justify-content-around align-items-center">
        <div class="col-xl-3 col-lg-4 col-md-3 d-flex mb-5 mb-md-0 aos-init aos-animate" data-aos="fade-right">
          <div class="row justify-content-center">
            <div class="col-8 col-md">
              <div class="mobile-screenshot rotate-left layer-2">
                <img src="assets/img/services-mobile.png" alt="Screenshot">
                <img src="assets/img/iphone-xr.svg" alt="iPhone XR">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-8 d-flex flex-column">
          <div class="mb-3">
            <span class="badge badge-primary">
              Apps
            </span>
            <h3 class="h1 mt-3" i18n="services|mobile - title">
              Desenvolvimento de aplicativos para smartphones e web.
            </h3>
            <p class="lead" i18n="services|mobile - description">
              Desenvolvemos aplicativos feitos sob medida para sua necessidade, empresa ou ideia. Utilizamos das tecnologias mais avançadas de mercado e do mais alto padrão de segurança.
            </p>
          </div>
          <div class="row">
            <div class="col-6">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/devices/iphone-x.svg">
                <title>Icon For iPhone-X</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z" fill="#000000" opacity="0.3"></path>
                    <path d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z" fill="#000000" fill-rule="nonzero"></path>
                </g>
            </svg>
              <div class="mt-2">
                <h5>Mobile</h5>
              </div>
              <p i18n="services|mobile - ios description">
                Aplicativos nativos escritos nas linguagens proprietárias iOS/Android, em Swift/Kotlin ou com o mais moderno da linguagem React Native.
              </p>
            </div>
            <div class="col-6">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
              height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
              data-src="assets/img/icons/theme/layout/layout-top-panel-2.svg">
              <title>Icon For Layout-top-panel-2</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M3,4 L20,4 C20.5522847,4 21,4.44771525 21,5 L21,7 C21,7.55228475 20.5522847,8 20,8 L3,8 C2.44771525,8 2,7.55228475 2,7 L2,5 C2,4.44771525 2.44771525,4 3,4 Z M10,10 L20,10 C20.5522847,10 21,10.4477153 21,11 L21,19 C21,19.5522847 20.5522847,20 20,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,11 C9,10.4477153 9.44771525,10 10,10 Z"
                  fill="#000000"></path>
                <rect fill="#000000" opacity="0.3" x="2" y="10" width="5" height="10" rx="1"></rect>
              </g>
            </svg>
              <div class="mt-2">
                <h5>Web</h5>
              </div>
              <p i18n="services|mobile - android description">
                Aplicativos web desenvolvidos no melhor do front end da atualidade, VueJS e Angular.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- POC e MVP -->
  <section class="" id="mvp">
    <div class="container">
      <div class="row align-items-center justify-content-between">
       
        <div class="col-lg-6 px-md-6 px-lg-0">
          <div class="mb-3">
            <span class="badge badge-primary">
              UI/UX
            </span>
            <h3 class="h1 mt-3" i18n="services|design - title">
              MVP e Prova de Conceito
            </h3>
            <p class="lead" i18n="services|design - description">

              Todo projeto precisa ser validado. Nós te ajudamos a construir o seu produto de maneira rápida e eficaz, coletando feedback e refinando casos de uso com seu usuário final. Uma vez validado, seu produto está pronto para ganhar escala!
            </p>
          </div>
          <div class="row">
            <div class="col-6">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/design/pen-ruler.svg">
                  <title>Icon For Pen&amp;ruller</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                      <path d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z" fill="#000000" opacity="0.3"></path>
                      <path d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z" fill="#000000"></path>
                  </g>
              </svg>
              <div class="mt-2">
                <h5>
                  Interfaces
                </h5>
              </div>
              <p i18n="services|design">
                Fluxo completo de telas já com foco na funcionalidade principal do seu produto.
              </p>
            </div>
            <div class="col-6">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/general/binocular.svg">
                  <title>Icon For Binocular</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                      <path d="M12.8434797,16 L11.1565203,16 L10.9852159,16.6393167 C10.3352654,19.064965 7.84199997,20.5044524 5.41635172,19.8545019 C2.99070348,19.2045514 1.55121603,16.711286 2.20116652,14.2856378 L3.92086709,7.86762789 C4.57081758,5.44197964 7.06408298,4.00249219 9.48973122,4.65244268 C10.5421727,4.93444352 11.4089671,5.56345262 12,6.38338695 C12.5910329,5.56345262 13.4578273,4.93444352 14.5102688,4.65244268 C16.935917,4.00249219 19.4291824,5.44197964 20.0791329,7.86762789 L21.7988335,14.2856378 C22.448784,16.711286 21.0092965,19.2045514 18.5836483,19.8545019 C16.158,20.5044524 13.6647346,19.064965 13.0147841,16.6393167 L12.8434797,16 Z M17.4563502,18.1051865 C18.9630797,18.1051865 20.1845253,16.8377967 20.1845253,15.2743923 C20.1845253,13.7109878 18.9630797,12.4435981 17.4563502,12.4435981 C15.9496207,12.4435981 14.7281751,13.7109878 14.7281751,15.2743923 C14.7281751,16.8377967 15.9496207,18.1051865 17.4563502,18.1051865 Z M6.54364977,18.1051865 C8.05037928,18.1051865 9.27182488,16.8377967 9.27182488,15.2743923 C9.27182488,13.7109878 8.05037928,12.4435981 6.54364977,12.4435981 C5.03692026,12.4435981 3.81547465,13.7109878 3.81547465,15.2743923 C3.81547465,16.8377967 5.03692026,18.1051865 6.54364977,18.1051865 Z" fill="#000000"></path>
                  </g>
              </svg>
              <div class="mt-2">
                <h5 i18n="services|design">
                  Experiência de uso
                </h5>
              </div>
              <p i18n="services|design">
                Acompanhamento da experiência e interação que os usuários estão tendo com os aplicativos.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-8 mt-4 mt-lg-0">
              <img src="assets/img/services-design.png" alt="Image" data-aos="fade-right" class="aos-init aos-animate">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- MENTORIA -->
  <section class="" id="mentoria">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-6">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-8 mt-4 mt-lg-0">
              <img src="assets/img/services-web.png" alt="Image" data-aos="fade-left" class="aos-init aos-animate">
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xl-5 px-md-6 px-lg-0">
          <div class="mb-3">
            <span class="badge badge-primary">
              Inovação
            </span>
            <h3 class="h1 mt-3" i18n="services|web - title">
              Mentoria em tecnologia
            </h3>
            <p class="lead" i18n="services|web - description">
              Nós temos decadas de experiência com tecnologia e já trabalhamos com centenas de empresas de todos os portes. Podemos te ajudar a trazer inovação para seu negócio. Também conseguimos te ajudar a trazer escalabilidade e pensar em novos canais de receita.  
            </p>
          </div>
          <div class="row">
            <div class="col-6">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/code/thunder-circle.svg">
                <title>Icon For Thunder-circle</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                    <path d="M12.4208204,17.1583592 L15.4572949,11.0854102 C15.6425368,10.7149263 15.4923686,10.2644215 15.1218847,10.0791796 C15.0177431,10.0271088 14.9029083,10 14.7864745,10 L12,10 L12,7.17705098 C12,6.76283742 11.6642136,6.42705098 11.25,6.42705098 C10.965921,6.42705098 10.7062236,6.58755277 10.5791796,6.84164079 L7.5427051,12.9145898 C7.35746316,13.2850737 7.50763142,13.7355785 7.87811529,13.9208204 C7.98225687,13.9728912 8.09709167,14 8.21352549,14 L11,14 L11,16.822949 C11,17.2371626 11.3357864,17.572949 11.75,17.572949 C12.034079,17.572949 12.2937764,17.4124472 12.4208204,17.1583592 Z" fill="#000000"></path>
                </g>
            </svg>
              <div class="mt-2">
                <h5 i18n="services|web - sistem title">
                  Metologia ágil
                </h5>
              </div>
              <p i18n="services|web - sistem description">
                Te ajudamos a transformar sua equipe de tecnologia com metodologias ágeis
              </p>
            </div>
            <div class="col-6">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/code/git-3.svg">
                <title>Icon For Git#3</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <path d="M7,11 L15,11 C16.1045695,11 17,10.1045695 17,9 L17,8 L19,8 L19,9 C19,11.209139 17.209139,13 15,13 L7,13 L7,15 C7,15.5522847 6.55228475,16 6,16 C5.44771525,16 5,15.5522847 5,15 L5,9 C5,8.44771525 5.44771525,8 6,8 C6.55228475,8 7,8.44771525 7,9 L7,11 Z" fill="#000000" opacity="0.3"></path>
                    <path d="M6,21 C7.1045695,21 8,20.1045695 8,19 C8,17.8954305 7.1045695,17 6,17 C4.8954305,17 4,17.8954305 4,19 C4,20.1045695 4.8954305,21 6,21 Z M6,23 C3.790861,23 2,21.209139 2,19 C2,16.790861 3.790861,15 6,15 C8.209139,15 10,16.790861 10,19 C10,21.209139 8.209139,23 6,23 Z" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M18,7 C19.1045695,7 20,6.1045695 20,5 C20,3.8954305 19.1045695,3 18,3 C16.8954305,3 16,3.8954305 16,5 C16,6.1045695 16.8954305,7 18,7 Z M18,9 C15.790861,9 14,7.209139 14,5 C14,2.790861 15.790861,1 18,1 C20.209139,1 22,2.790861 22,5 C22,7.209139 20.209139,9 18,9 Z" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z" fill="#000000" fill-rule="nonzero"></path>
                </g>
            </svg>
              <div class="mt-2">
                <h5>
                  Gestão
                </h5>
              </div>
              <p i18n="services|web - landing description">
                Te ajudamos a gerenciar suas demandas de desenvolvimento e tecnologia.
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </section>

  <!-- OTHER SERVICES -->
<!--   
  <section>
    <div class="container pb-md-6">
      <div class="row mb-4">
        <div class="col">
          <h2 i18n="services|other">
            Também somos especialistas em
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon"
            data-src="assets/img/icons/theme/communication/group.svg">
            <title>Icon For Group</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
              <path
                d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
              <path
                d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                fill="#000000" fill-rule="nonzero"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|other">
              Apoio contínuo
            </h5>
            <p i18n="services|other">
              Disponibilização de um squad ágil para desenvolver suas demandas.
            </p>
          </div>
        </div>
        
       
        <div class="col-md-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon"
            data-src="assets/img/icons/theme/shopping/chart-line-1.svg">
            <title>Icon For Chart-line#1</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
                fill="#000000" fill-rule="nonzero"></path>
              <path
                d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|other">
              Mentoria em tecnologia
            </h5>
            <p i18n="services|other">
              Te ajudamos a digitalizar sua empresa e trazer tecnologia pro seu produto.
            </p>
          </div>
        </div>

        <div class="col-md-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon"
            data-src="assets/img/icons/theme/files/cloud-upload.svg">
            <title>Icon For Cloud-upload</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
              <path
                d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                fill="#000000" fill-rule="nonzero"
                transform="translate(11.959697, 18.661508) rotate(-90.000000) translate(-11.959697, -18.661508) ">
              </path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|other">
              Provas de Conceito
            </h5>
            <p i18n="services|other">
              Te ajudamos a validar seu MVP e viabilizar seu projeto.
            </p>
          </div>
        </div>
       
      </div>
    </div>
  </section> -->

  <section class="bg-primary text-light">
    <div class="container">
      <div class="row mb-4">
        <div class="col">
          <h2 i18n="services|how we work">
            Como podemos te ajudar?
          </h2>
        </div>
      </div>
      <div class="row">

        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-white"
            data-src="assets/img/icons/theme/home/bulb-1.svg">
            <title>Icon For Bulb#1</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" opacity="0.3" cx="12" cy="9" r="8"></circle>
              <path
                d="M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z"
                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
              <path
                d="M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|how we work">Descoberta</h5>
            <div i18n="services|how we work">
              Entendemos as dores do seu negócio, suas ideias de inovação e ajudamos a formular soluções viáveis.
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-white"
            data-src="assets/img/icons/theme/general/binocular.svg">
            <title>Icon For Binocular</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M12.8434797,16 L11.1565203,16 L10.9852159,16.6393167 C10.3352654,19.064965 7.84199997,20.5044524 5.41635172,19.8545019 C2.99070348,19.2045514 1.55121603,16.711286 2.20116652,14.2856378 L3.92086709,7.86762789 C4.57081758,5.44197964 7.06408298,4.00249219 9.48973122,4.65244268 C10.5421727,4.93444352 11.4089671,5.56345262 12,6.38338695 C12.5910329,5.56345262 13.4578273,4.93444352 14.5102688,4.65244268 C16.935917,4.00249219 19.4291824,5.44197964 20.0791329,7.86762789 L21.7988335,14.2856378 C22.448784,16.711286 21.0092965,19.2045514 18.5836483,19.8545019 C16.158,20.5044524 13.6647346,19.064965 13.0147841,16.6393167 L12.8434797,16 Z M17.4563502,18.1051865 C18.9630797,18.1051865 20.1845253,16.8377967 20.1845253,15.2743923 C20.1845253,13.7109878 18.9630797,12.4435981 17.4563502,12.4435981 C15.9496207,12.4435981 14.7281751,13.7109878 14.7281751,15.2743923 C14.7281751,16.8377967 15.9496207,18.1051865 17.4563502,18.1051865 Z M6.54364977,18.1051865 C8.05037928,18.1051865 9.27182488,16.8377967 9.27182488,15.2743923 C9.27182488,13.7109878 8.05037928,12.4435981 6.54364977,12.4435981 C5.03692026,12.4435981 3.81547465,13.7109878 3.81547465,15.2743923 C3.81547465,16.8377967 5.03692026,18.1051865 6.54364977,18.1051865 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|how we work">
              Exploramos o mercado
            </h5>
            <div i18n="services|how we work">
              Após definirmos o escopo, te ajudamos a entender seus concorrentes e o seu cliente final.
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-white"
            data-src="assets/img/icons/theme/design/pen-ruler.svg">
            <title>Icon For Pen&amp;ruller</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|how we work">
              Prova de Conceito
            </h5>
            <div i18n="services|how we work">
              Rapidamente desenvolvemos protótipos, MVPs e provas de conceito para te ajudar a validar os seus produtos.
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-white"
            data-src="assets/img/icons/theme/devices/display-1.svg">
            <title>Icon For Display#1</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M11,20 L11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 L13,20 L15.5,20 C15.7761424,20 16,20.2238576 16,20.5 C16,20.7761424 15.7761424,21 15.5,21 L8.5,21 C8.22385763,21 8,20.7761424 8,20.5 C8,20.2238576 8.22385763,20 8.5,20 L11,20 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,16 C22,16.5522847 21.5522847,17 21,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M4.5,8 C4.22385763,8 4,8.22385763 4,8.5 C4,8.77614237 4.22385763,9 4.5,9 L13.5,9 C13.7761424,9 14,8.77614237 14,8.5 C14,8.22385763 13.7761424,8 13.5,8 L4.5,8 Z M4.5,10 C4.22385763,10 4,10.2238576 4,10.5 C4,10.7761424 4.22385763,11 4.5,11 L7.5,11 C7.77614237,11 8,10.7761424 8,10.5 C8,10.2238576 7.77614237,10 7.5,10 L4.5,10 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|how we work">
              Desenvolvimento
            </h5>
            <div i18n="services|how we work">
              Depois de ter um MVP validado, desenvolvemos seu produto final através de nossos times multidisciplinares de alta performance, utilizando as melhores tecnologias do mercado, e sempre focados em segurança.
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-white"
            data-src="assets/img/icons/theme/communication/clipboard-check.svg">
            <title>Icon For Clipboard-check</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                fill="#000000"></path>
              <path
                d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|how we work">
              Teste e publicação
            </h5>
            <div i18n="services|how we work">
              Temos uma equipe especializada em testes de todos os tipos para garantir a qualidade do seu produto.
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-white"
            data-src="assets/img/icons/theme/tools/tools.svg">
            <title>Icon For Tools</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M15.9497475,3.80761184 L13.0246125,6.73274681 C12.2435639,7.51379539 12.2435639,8.78012535 13.0246125,9.56117394 L14.4388261,10.9753875 C15.2198746,11.7564361 16.4862046,11.7564361 17.2672532,10.9753875 L20.1923882,8.05025253 C20.7341101,10.0447871 20.2295941,12.2556873 18.674559,13.8107223 C16.8453326,15.6399488 14.1085592,16.0155296 11.8839934,14.9444337 L6.75735931,20.0710678 C5.97631073,20.8521164 4.70998077,20.8521164 3.92893219,20.0710678 C3.1478836,19.2900192 3.1478836,18.0236893 3.92893219,17.2426407 L9.05556629,12.1160066 C7.98447038,9.89144078 8.36005124,7.15466739 10.1892777,5.32544095 C11.7443127,3.77040588 13.9552129,3.26588995 15.9497475,3.80761184 Z"
                fill="#000000"></path>
              <path
                d="M16.6568542,5.92893219 L18.0710678,7.34314575 C18.4615921,7.73367004 18.4615921,8.36683502 18.0710678,8.75735931 L16.6913928,10.1370344 C16.3008685,10.5275587 15.6677035,10.5275587 15.2771792,10.1370344 L13.8629656,8.7228208 C13.4724413,8.33229651 13.4724413,7.69913153 13.8629656,7.30860724 L15.2426407,5.92893219 C15.633165,5.5384079 16.26633,5.5384079 16.6568542,5.92893219 Z"
                fill="#000000" opacity="0.3"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="services|how we work">
              Apoio contínuo
            </h5>
            <div i18n="services|how we work">
              Através de rotinas de testes e desenvolvimento de novas funcionalidades, periodicamente uma nova versão de seu projeto é publicada.
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>


  <!-- CONTACT CTA -->
  <app-contact-cta></app-contact-cta>

</div>
<app-footer></app-footer>