<div class="navbar-container" id="hideNav">
  <nav class="navbar navbar-expand-lg bg-white position-fixed navbar-light shadow-3d">
    <div class="container">

      <!-- LOGO -->
      <a class="navbar-brand fade-page" routerLink="">
        <img src="assets/img/logo.svg" alt="Phurshell">
      </a>

      <!-- TOGGLER BUTTON -->
      <button class="navbar-toggler" type="button" data-toggle="modal" data-target="#modal">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          class="injected-svg icon navbar-toggler-open" data-src="assets/img/icons/interface/menu.svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <path
            d="M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17V17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12V12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12V12ZM4 6C3.44772 6 3 6.44772 3 7V7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7V7C21 6.44772 20.5523 6 20 6H4Z" fill="#212529">
          </path>
        </svg>
      </button>

      <!-- LINKS ON NAV -->
      <div class="navbar-collapse collapse justify-content-end">
        <div class="py-2 py-lg-0">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" i18n="navbar|" routerLink="{{ PathRoutes.Services }}">
                O que fazemos?
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" i18n="navbar|" routerLink="{{ PathRoutes.Portfolio }}">
                Projetos entregues
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" i18n="navbar|" routerLink="{{ PathRoutes.Team }}">
                Quem somos?
              </a>
            </li>
            <!-- <li class="nav-item" *ngIf="language == 'br'">
              <a class="nav-link" routerLink="{{ PathRoutes.Blog }}">
                Blog
              </a>
            </li> -->
          </ul>
        </div>
        <a class="btn btn-primary ml-lg-3" i18n="navbar|" routerLink="{{ PathRoutes.Contact }}">
          Entrar em contato
        </a>
      </div>

    </div>
  </nav>
</div>
