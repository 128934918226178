<app-navbar></app-navbar>


<div class="main-content pt-6">

  <form [formGroup]="form" (submit)="send()">

    <!-- JUMBOTRON -->
    <section class="pb-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 text-center text-lg-left mt-4 mb-lg-0">
            <h1 class="display-4 mb-0" data-aos="fade-up" data-aos-delay="100" i18n="contact|">
              Olá! Vamos conversar?
            </h1>
            <div class="my-4" data-aos="fade-up" data-aos-delay="200">
              <p class="lead" i18n="contact|">
                Você tem uma ideia mas não sabe por onde começar? Preencha os campos abaixo, ou se preferir nos envie um
                email no
              </p>
              <a href="mailto:contato@phurshell.com" class="lead text-dark">contato@phurshell.com</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ABOUT YOU -->
    <section class="pb-0">
      <div class="container" data-aos="fade-up" data-aos-delay="500">
        <div class="row">
          <div class="col-xl-12">
            <span class="badge badge-primary" i18n="contact|">Sobre você</span>
            <h2 class="mt-3" i18n="contact|">
              Informações de contato
            </h2>
          </div>
          <div class="col-12">
            <label for="input-group-example" i18n="contact|">
              Você é uma pessoa física ou jurídica?
              <small class="text-danger">*</small>
            </label>
            <div class="form-group">
              <select formControlName="client_type" class="custom-select custom-select-lg"
                [ngClass]="{ 'is-invalid': form.controls['client_type'].touched && !form.controls['client_type'].valid }">
                <!-- <option disabled="" [selected]="true" value="">Seu perfil<small> *</small></option> -->
                <option value="PJ" [selected]="true">Pessoa jurídica</option>
                <option value="PF">Pessoa física</option>
              </select>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                class="injected-svg icon" data-src="assets/img/icons/interface/arrow-caret.svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <path
                  d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z"
                  fill="#212529"></path>
              </svg>
              <div class="invalid-feedback" i18n="contact|">Você deve escolher uma opção</div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group">
              <label for="input-group-example" i18n="contact|">
                Nome
                <small class="text-danger">*</small>
              </label>
              <input type="text" formControlName="name" class="form-control form-control-lg" id="input-group-example"
                aria-describedby="input-group-example" placeholder="{{placeholders?.name}}"
                [ngClass]="{ 'is-invalid': form.controls['name'].touched && !form.controls['name'].valid }">
              <div class="invalid-feedback" i18n="contact|">Você deve preencher este campo corretamente</div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group">
              <label for="input-group-example" i18n="contact|">
                Empresa
              </label>
              <input type="text" formControlName="company" class="form-control form-control-lg" id="input-group-example"
                aria-describedby="input-group-example" placeholder="{{placeholders?.company}}">
            </div>
          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group">
              <label for="input-group-example">
                Email
                <small class="text-danger">*</small>
              </label>
              <input type="text" formControlName="email" class="form-control form-control-lg" id="input-group-example"
                aria-describedby="input-group-example" placeholder="{{placeholders?.email}}"
                [ngClass]="{ 'is-invalid': form.controls['email'].touched && !form.controls['email'].valid }">
              <div class="invalid-feedback" i18n="contact|">Você deve preencher este campo corretamente</div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group">
              <label for="input-group-example" i18n="contact|">
                Telefone
                <small class="text-danger">*</small>
              </label>
              <input type="text" formControlName="phone" class="form-control form-control-lg" id="input-group-example"
                aria-describedby="input-group-example" placeholder="{{placeholders?.phone}}"
                [ngClass]="{ 'is-invalid': form.controls['phone'].touched && !form.controls['phone'].valid }">
              <div class="invalid-feedback" i18n="contact|">Você deve preencher este campo corretamente</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ABOUT IDEIA -->
    <section class="pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <span class="badge badge-primary" i18n="contact|">Sobre sua empresa</span>
            <h2 class="mt-3" i18n="contact|">
              O que você está procurando?
              <span class="h2 text-danger">*</span>
            </h2>
          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group rounded bg-white p-3 border">
              <div class="custom-control custom-checkbox">
                <input (click)="checkbox('Desenvolvimento Mobile e Web')" type="checkbox" class="custom-control-input"
                  id="custom-checkbox-example-6">
                <label class="custom-control-label" for="custom-checkbox-example-6" i18n="contact|">
                  Desenvolvimento mobile e web
                  &nbsp;
                  <small class="text-muted">
                    (Sites e Aplicativos Móveis)
                  </small>
                </label>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-8">
            <div class="form-group rounded bg-white p-3 border">
              <div class="custom-control custom-checkbox">
                <input (click)="checkbox('Squad-As-A-Service')" type="checkbox" class="custom-control-input"
                  id="custom-checkbox-example-4">
                <label class="custom-control-label" for="custom-checkbox-example-4" i18n="contact|">
                  Time de desenvolvimento
                  &nbsp;
                  <small class="text-muted">
                    (Uma equipe só sua)
                  </small>
                </label>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group rounded bg-white p-3 border">
              <div class="custom-control custom-checkbox">
                <input (click)="checkbox('Mentoria e Inovação')" type="checkbox" class="custom-control-input"
                  id="custom-checkbox-example-5">
                <label class="custom-control-label" for="custom-checkbox-example-5" i18n="contact|">
                  Mentoria em tecnologia
                  &nbsp;
                  <small class="text-muted">
                    (Inovação)
                  </small>
                </label>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group rounded bg-white p-3 border">
              <div class="custom-control custom-checkbox">
                <input (click)="checkbox('Manutencao e apoio contínuo')" type="checkbox" class="custom-control-input"
                  id="custom-checkbox-example-10">
                <label class="custom-control-label" for="custom-checkbox-example-10" i18n="contact|">
                  Apoio contínuo
                  &nbsp;
                  <small class="text-muted">
                    (Manutenção e novas funcionalidades)
                  </small>
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <!-- DESCRIPTION -->
    <section class="pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-8">
            <h2 i18n="contact|">Caso queira, descreva melhor o que está buscando.</h2>
          </div>
          <!-- <div class="col-xl-12">
            <div class="form-group">
              <label for="input-group-example"  i18n="contact|">
                Seu projeto ou ideia já possui um nome?
              </label>
              <input type="text" formControlName="project_name" class="form-control form-control-lg"
                id="input-group-example" aria-describedby="input-group-example" placeholder="{{placeholders?.project_name}}">
            </div>
          </div> -->
          <div class="col-xl-12">
            <div class="form-group">
              <label for="textarea-example-1" i18n="contact|">
                Descreva sua necessidade
                <!-- <small class="text-danger">*</small> -->
              </label>
              <textarea formControlName="project_description" class="form-control form-control-lg"
                id="textarea-example-1" placeholder="{{placeholders?.project_description}}" rows="3"
                [ngClass]="{ 'is-invalid': form.controls['project_description'].touched && !form.controls['project_description'].valid }">
              </textarea>
              <div class="invalid-feedback" i18n="contact|">Você deve preencher este campo</div>
            </div>
          </div>
          <!-- <div class="col-xl-12">
            <div class="form-group">
              <label for="textarea-example-1"  i18n="contact|">Referências</label>
              <textarea formControlName="project_references" class="form-control form-control-lg"
                id="textarea-example-1" placeholder="{{placeholders?.project_references}}" rows="3"></textarea>
            </div>
          </div> -->
        </div>
      </div>
    </section>

    <!-- BUDGET -->
    <section class="pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <h2 class="mt-3" i18n="contact|">
              Orçamento
            </h2>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="input-group-example" i18n="contact|">
                Qual é seu orçamento para esse projeto?
              </label>
              <input type="text" formControlName="budget" class="form-control form-control-lg" id="input-group-example"
                aria-describedby="input-group-example" placeholder="R$100.000,00"
                [ngClass]="{ 'is-invalid': form.controls['budget'].touched && !form.controls['budget'].valid }">
              <div class="invalid-feedback" i18n="contact|">Você deve preencher este campo corretamente</div>
            </div>
          </div>
          <div class="col-12">

            <a routerLink="{{ PathRoutes.Calculator }}" target="_blank" class="link-primary" *ngIf="language == 'br'">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
                data-src="assets/img/icons/theme/shopping/calculator.svg">
                <title>Icon For Calculator</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                  <rect fill="#000000" opacity="0.3" x="7" y="4" width="10" height="4"></rect>
                  <path
                    d="M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 Z M8,16 C8.55228475,16 9,15.5522847 9,15 C9,14.4477153 8.55228475,14 8,14 C7.44771525,14 7,14.4477153 7,15 C7,15.5522847 7.44771525,16 8,16 Z M12,12 C12.5522847,12 13,11.5522847 13,11 C13,10.4477153 12.5522847,10 12,10 C11.4477153,10 11,10.4477153 11,11 C11,11.5522847 11.4477153,12 12,12 Z M12,16 C12.5522847,16 13,15.5522847 13,15 C13,14.4477153 12.5522847,14 12,14 C11.4477153,14 11,14.4477153 11,15 C11,15.5522847 11.4477153,16 12,16 Z M16,12 C16.5522847,12 17,11.5522847 17,11 C17,10.4477153 16.5522847,10 16,10 C15.4477153,10 15,10.4477153 15,11 C15,11.5522847 15.4477153,12 16,12 Z M16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.4477153,14 15,14.4477153 15,15 C15,15.5522847 15.4477153,16 16,16 Z M16,20 C16.5522847,20 17,19.5522847 17,19 C17,18.4477153 16.5522847,18 16,18 C15.4477153,18 15,18.4477153 15,19 C15,19.5522847 15.4477153,20 16,20 Z M8,18 C7.44771525,18 7,18.4477153 7,19 C7,19.5522847 7.44771525,20 8,20 L12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 L8,18 Z M7,4 L7,8 L17,8 L17,4 L7,4 Z"
                    fill="#000000"></path>
                </g>
              </svg>
              <span i18n="contact cta|contact">
                Quer saber quanto custa um aplicativo?
              </span>
            </a>

          </div>
        </div>
      </div>
    </section>

    <!-- ABOUT COMPANY -->
    <section class="pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <h2 i18n="contact|">
              Dados da sua empresa
              <span class="h2 text-danger">*</span>
            </h2>
          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group">

              <select formControlName="segment" class="custom-select custom-select-lg"
                [ngClass]="{ 'is-invalid': form.controls['segment'].touched && !form.controls['segment'].valid }">

                <option disabled="" [selected]="true" value="">Segmento da empresa <small>*</small></option>
                <option value="Automotive">Automotivo</option>
                <option value="Communications">Telecomunicação</option>
                <option value="Financial Services">Serviços Financeiros</option>
                <option value="Health Care">Saúde</option>
                <option value="Tech">Tecnologia</option>
                <option value="Higher_Ed">Educação</option>
                <option value="Life Sciences">Farmacêutica e Biotecnologia</option>
                <option value="Manufacturing">Manufatura</option>
                <option value="Media">Meios de Comunicação</option>
                <option value="Nonprofit">ONG e Organizações sem fins lucrativos</option>
                <option value="Public Sector">Setor Público</option>
                <option value="Retail Consumer">Varejo e Bens de Consumo</option>
                <option value="Other">Outro</option>
              </select>
              <!-- <select formControlName="schedule" class="custom-select custom-select-lg"
                [ngClass]="{ 'is-invalid': form.controls['schedule'].touched && !form.controls['schedule'].valid }">
                <option value="" [selected]="true"  i18n="contact|">Estimativa de prazo <small>*</small></option>
         
                <option value="De 1 a 3 meses"  i18n="contact|">De 1 a 3 meses</option>
                <option value="Mais de 3 meses"  i18n="contact|">Mais de 3 meses</option>
                <option value="Não tenho"  i18n="contact|">Não tenho</option>
              </select> -->

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                class="injected-svg icon" data-src="assets/img/icons/interface/arrow-caret.svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <path
                  d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z"
                  fill="#212529"></path>
              </svg>
              <div class="invalid-feedback" i18n="contact|">Você deve escolher uma opção</div>
            </div>

          </div>
          <div class="col-xl-6 col-lg-8">
            <div class="form-group">

              <select formControlName="company_size" class="custom-select custom-select-lg"
                [ngClass]="{ 'is-invalid': form.controls['company_size'].touched && !form.controls['company_size'].valid }">
                <option disabled="" [selected]="true" value="">Tamanho da empresa <small>*</small></option>
                <option value="1 a 20">1 - 20 funcionários</option>
                <option value="21 a 50">21 - 50 funcionários</option>
                <option value="51 a 300">51 - 300 funcionários</option>
                <option value="301 a 1000">301 - 1,000 funcionários</option>
                <option value="1001 a 2000">1,001 - 2,000 funcionários</option>
                <option value="2001 ou mais">2,001+ funcionários</option>
              </select>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                class="injected-svg icon" data-src="assets/img/icons/interface/arrow-caret.svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <path
                  d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z"
                  fill="#212529"></path>
              </svg>
              <div class="invalid-feedback" i18n="contact|">Você deve escolher uma opção</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- PHURSHELL -->
    <section class="pb-0">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <span class="badge badge-primary" i18n="contact|">Sobre a Phurshell</span>
            <h2 class="mt-3" i18n="contact|">
              Como você nos conheceu?
              <span class="h2 text-danger">*</span>
            </h2>
          </div>
          <div class="col-xl-12 col-lg-8">
            <div class="form-group">
              <select formControlName="how_know_phurshell" class="custom-select custom-select-lg"
                [ngClass]="{ 'is-invalid': form.controls['how_know_phurshell'].touched && !form.controls['how_know_phurshell'].valid }">
                <option value="" [selected]="true" i18n="contact|">Como conheceu a Phurshell? <small>*</small></option>
                <option value="Pesquisa no Google" i18n="contact|">Pesquisa no Google</option>
                <option value="Indicação de um amigo" i18n="contact|">Indicação de um amigo</option>
                <option value="Redes sociais" i18n="contact|">Redes sociais</option>
                <option value="Outra forma" i18n="contact|">Outra forma</option>
              </select>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                class="injected-svg icon" data-src="assets/img/icons/interface/arrow-caret.svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <path
                  d="M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z"
                  fill="#212529"></path>
              </svg>
              <div class="invalid-feedback" i18n="contact|">Você deve escolher uma opção</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- SEND BUTTON -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="form-group text-center">
              <button class="btn btn-lg btn-primary btn-block mb-2 {{language}}" type="submit" [disabled]="!form.valid"
                *ngIf="!success && !error">
                <span *ngIf="!loading">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
                    data-src="assets/img/icons/theme/communication/sending.svg">
                    <title>Icon For Sending</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M8,13.1668961 L20.4470385,11.9999863 L8,10.8330764 L8,5.77181995 C8,5.70108058 8.01501031,5.63114635 8.04403925,5.56663761 C8.15735832,5.31481744 8.45336217,5.20254012 8.70518234,5.31585919 L22.545552,11.5440255 C22.6569791,11.5941677 22.7461882,11.6833768 22.7963304,11.794804 C22.9096495,12.0466241 22.7973722,12.342628 22.545552,12.455947 L8.70518234,18.6841134 C8.64067359,18.7131423 8.57073936,18.7281526 8.5,18.7281526 C8.22385763,18.7281526 8,18.504295 8,18.2281526 L8,13.1668961 Z"
                        fill="#000000"></path>
                      <path
                        d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M4,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L4,8 C3.44771525,8 3,7.55228475 3,7 C3,6.44771525 3.44771525,6 4,6 Z"
                        fill="#000000" opacity="0.3"></path>
                    </g>
                  </svg>
                  <span i18n="contact|"> Enviar</span>


                </span>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
              </button>
              <div class="alert alert-success border-0" role="alert" *ngIf=success>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-success"
                  data-src="assets/img/icons/theme/communication/readed-mail.svg">
                  <title>Icon For Readed-mail</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z"
                      fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                    <path
                      d="M12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.98630124,11 4.48466491,11.0516454 4,11.1500272 L4,7 C4,5.8954305 4.8954305,5 6,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,16 C22,17.1045695 21.1045695,18 20,18 L12.9835977,18 Z M19.1444251,6.83964668 L13,10.1481833 L6.85557487,6.83964668 C6.4908718,6.6432681 6.03602525,6.77972206 5.83964668,7.14442513 C5.6432681,7.5091282 5.77972206,7.96397475 6.14442513,8.16035332 L12.6444251,11.6603533 C12.8664074,11.7798822 13.1335926,11.7798822 13.3555749,11.6603533 L19.8555749,8.16035332 C20.2202779,7.96397475 20.3567319,7.5091282 20.1603533,7.14442513 C19.9639747,6.77972206 19.5091282,6.6432681 19.1444251,6.83964668 Z"
                      fill="#000000"></path>
                  </g>
                </svg>
                &nbsp;
                <strong i18n="contact|">
                  Enviado com sucesso!
                </strong>
              </div>
              <div *ngIf=error i18n="contact|">Ocorreu um erro ao enviar seu email!</div>
              <p class="mt-4" *ngIf="!form.valid">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-dark"
                  data-src="assets/img/icons/theme/code/warning-1-circle.svg">
                  <title>Icon For Warning-1-circle</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                    <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"></rect>
                    <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"></rect>
                  </g>
                </svg>
                <strong class="text-dark" i18n="contact|" i18n="contact|">
                  <span style="color:red"> Atenção! </span>Todos os campos com asterisco (*), devem ser preenchidos!
                </strong>
              </p>
              <small class="text-success" *ngIf=success i18n="contact|">
                Você receberá um email de confirmação, em breve entraremos em contato!
              </small>
            </div>
          </div>
        </div>
      </div>
    </section>

  </form>

  <!-- CONTACT EXTRAS -->
  <section class="mb-6">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/map/marker-1.svg">
            <title>Icon For Marker#1</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z"
                fill="#000000" fill-rule="nonzero"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="contact|">Conheça nosso escritório</h5>
            <p i18n="contact|">
              Nossa escritório está há três quadras da Avenida Paulista. Estaremos sempre com as portas abertas pra
              tomar
              um café e ouvir boas ideias.
            </p>
            <a href="https://maps.google.com/?q=Phurshell | Design e desenvolvimento de apps - Rua Haddock Lobo, sala 609 - Cerqueira César, São Paulo - SP"
              i18n="contact|">
              Haddock Lobo 131, sala 609 - SP
            </a>
          </div>
        </div>
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary"
            data-src="assets/img/icons/theme/communication/sending-mail.svg">
            <title>Icon For Sending mail</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="contact|">Nos envie um email</h5>
            <p i18n="contact|">
              Se você precisar, também pode nos enviar um email contando sua ideia diretamente para nosso email de
              relacionamento.
            </p>
            <a href="mailto:contato@phurshell.com">contato@phurshell.com</a>
          </div>
        </div>
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/communication/active-call.svg">
            <title>Icon For Active-call</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M13.0799676,14.7839934 L15.2839934,12.5799676 C15.8927139,11.9712471 16.0436229,11.0413042 15.6586342,10.2713269 L15.5337539,10.0215663 C15.1487653,9.25158901 15.2996742,8.3216461 15.9083948,7.71292558 L18.6411989,4.98012149 C18.836461,4.78485934 19.1530435,4.78485934 19.3483056,4.98012149 C19.3863063,5.01812215 19.4179321,5.06200062 19.4419658,5.11006808 L20.5459415,7.31801948 C21.3904962,9.0071287 21.0594452,11.0471565 19.7240871,12.3825146 L13.7252616,18.3813401 C12.2717221,19.8348796 10.1217008,20.3424308 8.17157288,19.6923882 L5.75709327,18.8875616 C5.49512161,18.8002377 5.35354162,18.5170777 5.4408655,18.2551061 C5.46541191,18.1814669 5.50676633,18.114554 5.56165376,18.0596666 L8.21292558,15.4083948 C8.8216461,14.7996742 9.75158901,14.6487653 10.5215663,15.0337539 L10.7713269,15.1586342 C11.5413042,15.5436229 12.4712471,15.3927139 13.0799676,14.7839934 Z"
                fill="#000000"></path>
              <path
                d="M14.1480759,6.00715131 L13.9566988,7.99797396 C12.4781389,7.8558405 11.0097207,8.36895892 9.93933983,9.43933983 C8.8724631,10.5062166 8.35911588,11.9685602 8.49664195,13.4426352 L6.50528978,13.6284215 C6.31304559,11.5678496 7.03283934,9.51741319 8.52512627,8.02512627 C10.0223249,6.52792766 12.0812426,5.80846733 14.1480759,6.00715131 Z M14.4980938,2.02230302 L14.313049,4.01372424 C11.6618299,3.76737046 9.03000738,4.69181803 7.1109127,6.6109127 C5.19447112,8.52735429 4.26985715,11.1545872 4.51274152,13.802405 L2.52110319,13.985098 C2.22450978,10.7517681 3.35562581,7.53777247 5.69669914,5.19669914 C8.04101739,2.85238089 11.2606138,1.72147333 14.4980938,2.02230302 Z"
                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="contact|">Pode ligar também</h5>
            <p i18n="contact|">
              Agora se você precisar de um canal direto de comunicação, pode nos ligar. Fique a vontade pra tirar
              qualquer dúvida.
            </p>
            <a href="tel:11991341871">+55 (11) 99134-1871</a>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>