<app-navbar></app-navbar>
<div class="main-content pt-6">

  <!-- JUMBOTRON -->
  <section class="pb-0">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 text-center text-lg-left mt-4 mb-lg-0">
          <h1 class="display-4 mb-0" data-aos="fade-up" data-aos-delay="100" i18n="portfolio|">
            Projetos entregues
          </h1>
          <div class="my-4" data-aos="fade-up" data-aos-delay="200">
            <p class="lead" >

              <span i18n="portfolio|">Na Phurshell, buscamos o que é mais importante e ajudamos os usuários a encontrar o caminho em uma
                interface atraente.</span>
              <br>

              <span i18n="portfolio|">Conheça um pouco dos projetos que trabalhamos!</span>

           
              
            </p>
            
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- COUNTERS -->
  <section class="">
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-md-4 mb-3 mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="300">
          <p class="lead mb-0" i18n="portfolio|">
            Mais de
          </p>
          <span class="display-4 text-primary mb-0 highlight" [countUp]="endValApps" [options]="counterApps">
            0+
          </span>
          <p class="lead" i18n="portfolio|">
            desenvolvidos
          </p>
        </div>
        <div class="col-md-4 mb-3 mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="500">
          <p class="lead mb-0" i18n="portfolio|">
            Em menos de
          </p>
          <span class="display-4 text-primary-2 d-block mb-0" >
           <span [countUp]="endValYears" [options]="counterYears">0</span>   <span i18n="portfolio|">anos</span>  
          </span>
          <p class="lead" i18n="portfolio|">
            de experiência
          </p>
        </div>
      </div>
    </div>
  </section>

  


  <!-- CHEFE DE PLANTÃO / IMG TO LEFT -->
  <section class="pt-0 pb-2">
    <div class="card bg-chefedeplantao b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_chefedeplantao.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_chefedeplantao.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Chefe de Plantão
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Aplicativo que ajuda médicos recém formados com suas consultas. Projeto que construímos todos os produtos, desde a identidade até todos os aplicativos.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Logo</span>
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/br/app/chefe-de-plant%C3%A3o/id1581192044"
                  target="_blank" class="btn btn-dark mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.ph.medicoplantao.androidapp" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
              </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- SAPIENS / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-sapiens b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_4traders.png" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    4Traders
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Aplicativo de informações de mercado para quem investe em ações. Tiramos o projeto do papel criando e desenvolvendo todos os produtos desde a concepção até a publicação.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Logo</span>
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/br/app/4traders/id1578387354?l=en"
                  target="_blank" class="btn btn-dark text-white mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.ph.traders.androidapp" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
                </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_sapiens.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- GOPLAYER / IMG TO LEFT -->
  <section class="pt-0 pb-2">
    <div class="card bg-goplayer b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_goplayer.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_goplayer.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Goplayer
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Aplicativo conecta jogadores de futebol com times. Tiramos o projeto do papel criando e desenvolvendo todos os produtos desde a concepção até a publicação.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Logo</span>
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/us/app/goplayer/id1537214628"
                  target="_blank" class="btn btn-dark text-white mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.goplayeruser.androidapp" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- GANHO / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-ganho b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_ganho.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Ganho
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Aplicativo que reuni a vitrine e os descontos de todas as lojas da cidade. Tiramos o projeto do papel criando e desenvolvendo todos os produtos desde a concepção até a publicação.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Logo</span>
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/us/app/ganhô/id1517260721"
                  target="_blank" class="btn btn-dark mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.ganho.androidapp" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
              </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_ganho.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- MEGATROCA / IMG TO LEFT -->
  <section class="pt-0 pb-2">
    <div class="card bg-megatroca b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_megatroca.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_megatroca.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Megatroca
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Aplicativo para venda e compra de veículos. Tiramos o projeto do papel criando e desenvolvendo todos os produtos desde a concepção até a publicação.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Logo</span>
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/us/app/mega-troca/id1511267470"
                  target="_blank" class="btn btn-dark text-white mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.2git6z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.megatroca" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- GOIN / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-goin b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_goin.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Goin
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Montando um time exclusivo para o desenvolvimento do aplicativo que trás entretenimento na palma das mãos pra quem não aguenta ficar sentado no sofá.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Frontend</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Web</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                  <span class="badge badge-white mr-1 mb-1">PDV</span>
                  <span class="badge badge-white mr-1 mb-1">RH</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_goin.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- DR LUKE / IMG TO LEFT -->
  <section class="pt-0 pb-2">
    <div class="card bg-drluke b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_drluke.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_drluke.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Dr Luke
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Desenvolvemos a versão Android do aplicativo que está ajudando as mães com a vacinação de seus filhos.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/us/app/dr-luke-saúde-infantil/id1375557280"
                  target="_blank" class="btn btn-dark mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=br.com.drluke.androidApp" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
              </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- HAPGRAB / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-hapgrab b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_hapgrab.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Hapgrab
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Desenvolvemos o sistema web feitos sob medida, realizamos ajustes nas versões iOS e Android e a construção do backoffice para gestão das empresas cadastradas.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Frontend</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                  <span class="badge badge-white mr-1 mb-1">Web</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_hapgrab.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- DEVOTEE / IMG TO LEFT -->
  <section class="pt-0 pb-2">
    <div class="card bg-devotee b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_devotee.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_devotee.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Devotee
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Tivemos a honra de tirar do papel o único aplicativo de relacionamento exclusivo para deficientes físicos. Desde a concepção da ideia até o desenvolvimento do iOS e Android.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Web</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                  <span class="badge badge-white mr-1 mb-1" i18n="portfolio|">Sistema administrativo</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- GENTE / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-gente b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_gente.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Gente Seguradora
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Estivemos dia-a-dia estudando o fluxo de dados de nosso cliente. Baseado na maneira como as pessoas utilizavam o serviços da Gente Seguradora nós construimos os aplicativos para digitalizar diversos processos que eram feitos manualmente.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                <span class="badge badge-white mr-1 mb-1">Backend</span>
                <span class="badge badge-white mr-1 mb-1">Frontend</span>
                <span class="badge badge-white mr-1 mb-1">Blog</span>
                <span class="badge badge-white mr-1 mb-1">Web</span>
                <span class="badge badge-white mr-1 mb-1">iOS</span>
                <span class="badge badge-white mr-1 mb-1">Android</span>
                <span class="badge badge-white mr-1 mb-1" i18n="portfolio|">Sistema administrativo</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/us/app/gente-seguradora/id1318549047"
                  target="_blank" class="btn btn-dark mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.phurshell.gente" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
              </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_gente.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  <!-- MOVSPOT / IMG TO LEFT -->
  <section class="pt-0 pb-2">
    <div class="card bg-movspot b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_movspot.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_movspot.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Movspot
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Tiramos a ideia do papel, estudamos o mercado, criamos a identidade e desenvolvemos a única plataforma que mostra onde os filmes foram gravados na internet.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1" i18n="portfolio|">Identidade visual</span>
                  <span class="badge badge-white mr-1 mb-1">Logo</span>
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Web</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                  <span class="badge badge-white mr-1 mb-1" i18n="portfolio|">Sistema administrativo</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/us/app/movspot/id1399158603"
                  target="_blank" class="btn btn-dark mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.phurshell.movspotApp" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
              </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- QUOTAS / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-quotas b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_quotas.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Quotas Ambipar
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Aplicativo de incentivo para melhorar a performance e o dia-a-dia dos funiconários da Ambipar. Além de controle das atividades, você pode acumular pontos e retirar em vantagens.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_quotas.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- 4I20 / IMG TO LEFT -->
  <section class="pt-0 pb-2">
    <div class="card bg-4i20 b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_4i20.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_4i20.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Momentos
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Trouxemos um novo conceito visual para o aplicativo, desde a identidade visual, logo, mockup até a implementação no aplicativo iOS.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1"i18n="portfolio|">Identidade visual</span>
                  <span class="badge badge-white mr-1 mb-1">Logo</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- POLIFITEMA / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-polifitema b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_polifitema.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Polifitema
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  Desenvolvemos os aplicativos para inspeção e levantamento de irregularidades nas fitas de segurança fabricados pela Polifitema.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_polifitema.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  <!-- BUYAPP / IMG TO LEFT -->
  <section class="pt-0 pb-2">
    <div class="card bg-buyapp b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_buyapp.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_buyapp.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Buyapp
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  O aplicativo que ajuda você a fazer suas compras seja em mercados ou em distribuidores.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div>
                  <a href="https://apps.apple.com/us/app/buyapp/id1544525373"
                  target="_blank" class="btn btn-dark text-white mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.ph.buyapp.androidapp" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- BLUU / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-bluu b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_bluu.svg" class="mr-3 rounded-icon shadow-lg" alt="Image" width="75px">
                <div>
                  <h1 class="mb-0">
                    Bluu
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  A rede aplicativo que ajuda donos de pet a encontrar lugares e outros donos, trazendo interação social entre donos e seus pets.
                  <!-- Somos os responsáveis pela manutenção, desde a concepção de novas ideias até o desenvolvimento e publicação dos aplicativos. -->
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div class="opacity-30">
                  <button class="btn btn-dark text-white mr-2 mb-2 disabled">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/tools/hummer.svg">
                      <title>Icon For Hummer</title>
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                          <path d="M18.4246212,12.6464466 L21.2530483,9.81801948 C21.4483105,9.62275734 21.764893,9.62275734 21.9601551,9.81801948 L22.6672619,10.5251263 C22.862524,10.7203884 22.862524,11.0369709 22.6672619,11.232233 L19.8388348,14.0606602 C19.6435726,14.2559223 19.3269901,14.2559223 19.131728,14.0606602 L18.4246212,13.3535534 C18.2293591,13.1582912 18.2293591,12.8417088 18.4246212,12.6464466 Z M3.22182541,17.9497475 L13.1213203,8.05025253 C13.5118446,7.65972824 14.1450096,7.65972824 14.5355339,8.05025253 L15.9497475,9.46446609 C16.3402718,9.85499039 16.3402718,10.4881554 15.9497475,10.8786797 L6.05025253,20.7781746 C5.65972824,21.1686989 5.02656326,21.1686989 4.63603897,20.7781746 L3.22182541,19.363961 C2.83130112,18.9734367 2.83130112,18.3402718 3.22182541,17.9497475 Z" fill="#000000" opacity="0.3"></path>
                          <path d="M12.3890873,1.28248558 L12.3890873,1.28248558 C15.150511,1.28248558 17.3890873,3.52106183 17.3890873,6.28248558 L17.3890873,10.7824856 C17.3890873,11.058628 17.1652297,11.2824856 16.8890873,11.2824856 L12.8890873,11.2824856 C12.6129449,11.2824856 12.3890873,11.058628 12.3890873,10.7824856 L12.3890873,1.28248558 Z" fill="#000000" transform="translate(14.889087, 6.282486) rotate(-45.000000) translate(-14.889087, -6.282486) "></path>
                      </g>
                  </svg>
                    <span i18n="portfolio|">
                      Em construção
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                <img src="assets/img/screenshot_bluu.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- PSIAPP / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-psiapp b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_psiapp.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0">
                    Psiapp
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <p class="lead mb-3" i18n="portfolio|">
                  O aplicativo que conecta psicólogos a pacientes. Criamos todo fluxo e o desenvolvimento completo de todos os produtos.
                </p>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  O que fizemos?
                </small>
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <span class="badge badge-white mr-1 mb-1">Logo</span>
                  <span class="badge badge-white mr-1 mb-1">Mockup</span>
                  <span class="badge badge-white mr-1 mb-1">Backend</span>
                  <span class="badge badge-white mr-1 mb-1">Backoffice</span>
                  <span class="badge badge-white mr-1 mb-1">iOS</span>
                  <span class="badge badge-white mr-1 mb-1">Android</span>
                </div>
                <small class="mb-2 text-muted" i18n="portfolio|">
                  Conheça o projeto
                </small>
                <div class="opacity-30">
                  <button class="btn btn-dark text-white mr-2 mb-2 disabled">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/tools/hummer.svg">
                      <title>Icon For Hummer</title>
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                          <path d="M18.4246212,12.6464466 L21.2530483,9.81801948 C21.4483105,9.62275734 21.764893,9.62275734 21.9601551,9.81801948 L22.6672619,10.5251263 C22.862524,10.7203884 22.862524,11.0369709 22.6672619,11.232233 L19.8388348,14.0606602 C19.6435726,14.2559223 19.3269901,14.2559223 19.131728,14.0606602 L18.4246212,13.3535534 C18.2293591,13.1582912 18.2293591,12.8417088 18.4246212,12.6464466 Z M3.22182541,17.9497475 L13.1213203,8.05025253 C13.5118446,7.65972824 14.1450096,7.65972824 14.5355339,8.05025253 L15.9497475,9.46446609 C16.3402718,9.85499039 16.3402718,10.4881554 15.9497475,10.8786797 L6.05025253,20.7781746 C5.65972824,21.1686989 5.02656326,21.1686989 4.63603897,20.7781746 L3.22182541,19.363961 C2.83130112,18.9734367 2.83130112,18.3402718 3.22182541,17.9497475 Z" fill="#000000" opacity="0.3"></path>
                          <path d="M12.3890873,1.28248558 L12.3890873,1.28248558 C15.150511,1.28248558 17.3890873,3.52106183 17.3890873,6.28248558 L17.3890873,10.7824856 C17.3890873,11.058628 17.1652297,11.2824856 16.8890873,11.2824856 L12.8890873,11.2824856 C12.6129449,11.2824856 12.3890873,11.058628 12.3890873,10.7824856 L12.3890873,1.28248558 Z" fill="#000000" transform="translate(14.889087, 6.282486) rotate(-45.000000) translate(-14.889087, -6.282486) "></path>
                      </g>
                  </svg>
                    <span i18n="portfolio|">
                      Em construção
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_psiapp.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  <!-- CAROUSEL -->
  <section class="pb-0">
  <div class="container">
    <div class="row justify-content-center text-center mb-6">
      <div class="col-xl-8 col-lg-9">
        <h3 class="h1 mx-xl-6" i18n="portfolio|">
          Alguns outros projetos incríveis que entregamos
        </h3>
        <p class="lead" i18n="portfolio|">
          Além de centenas de aplicativos, também tivemos a oportunidade de entregar diversar plataformas de alto impacto para milhares de usuários
        </p>
      </div>
    </div>
  </div>
  <div class="main-carousel">
    <div class="carousel-cell">
      <img src="assets/img/screenshot_notanova.jpg" class="carousel-cell-image border shadow-3d hover-shadow-3d">
    </div>
    <div class="carousel-cell">
      <img src="assets/img/screenshot_nesha.jpg" class="carousel-cell-image border shadow-3d hover-shadow-3d">
    </div>
    <div class="carousel-cell">
      <img src="assets/img/screenshot_chefedeplantao.jpg" class="carousel-cell-image border shadow-3d hover-shadow-3d">
    </div>
    <div class="carousel-cell ">
      <img src="assets/img/screenshot_movspot.jpg" class="carousel-cell-image border shadow-3d hover-shadow-3d">
    </div>
    <div class="carousel-cell ">
      <img src="assets/img/screenshot_megatroca.jpg" class="carousel-cell-image border shadow-3d hover-shadow-3d">
    </div>
  </div>
  </section>

  <!-- CONTACT CTA -->
  <app-contact-cta></app-contact-cta>

</div>


<app-footer></app-footer>