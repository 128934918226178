<app-navbar></app-navbar>
<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-9">
        <section class="pb-0 pb-2">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a routerLink="{{ PathRoutes.Blog }}">Blog</a>
                </li>
                <li class="breadcrumb-item">
                  <span>
                    Post
                  </span>
                </li>
              </ol>
            </nav>
          </div>
          <h1>
            {{ post.title.rendered }}
          </h1>
        </section>
        <section class="p-0">
          <img src="{{ post.better_featured_image.source_url }}" alt="Image" class="rounded">
          <article class="article">
            <p [innerHtml]="post.content.rendered" class="lead"></p>
          </article>
        </section>
      </div>
      <div class="col-md-4 col-lg-3 d-none d-md-block">
        <div class="sticky-top pb-3">
          <img src="assets/img/banner-ad.png" alt="Image" class="rounded shadow-3d">
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>