<app-navbar></app-navbar>
<div class="main-content pt-6">

  <!-- JUMBOTRON -->
  <section>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 text-center text-lg-left mt-4 mb-lg-0">
          <h1 class="display-4 mb-0" data-aos="fade-up" data-aos-delay="100" i18n="team|team">
            Quem somos?
          </h1>
          <div class="my-4" data-aos="fade-up" data-aos-delay="200">
            <p class="lead" i18n="team|team">
              Na Phurshell, te ajudamos a transformar seu negócio combinando design minimalista com máxima usabilidade.
              Temos equipes multidisciplinares que podem te ajudar em todas as fases dos seus produtos.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ABOUT -->
  <section>
    <div class="container aos-init aos-animate" data-aos="fade-up">
      <div class="row align-items-center justify-content-around">
        <div class="col-md-7 col-xl-6">
          <div class="row justify-content-center">
            <div class="col-xl-12 pr-5">
              <div class="my-3">
                <span class="h1" i18n="team|team">
                  Um time que faz acontecer!
                </span>
              </div>
              <p class="lead" i18n="team|team">
                Somos uma equipe de entusiastas em tecnologia sempre focada em trazer o que há de mais novo no mercado
                para nossos parceiros.
              </p>
              <p class="lead" i18n="team|team">Gostamos do que fazemos e nos divertimos enquanto estamos trabalhando.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-xl-6 mb-4 mb-md-0">
          <img src="assets/img/team-1.jpg" alt="Image" class="rounded shadow-3d">
        </div>
      </div>
    </div>
  </section>


  <section>
    <div class="container">
      <div class="row mb-4 aos-init aos-animate" data-aos="fade-up">
        <div class="col">
          <h2 i18n="team|team">
            Porque trabalhar com a gente?
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/home/alarm-clock.svg">
            <title>Icon For Alarm-clock</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M7.14319965,19.3575259 C7.67122143,19.7615175 8.25104409,20.1012165 8.87097532,20.3649307 L7.89205065,22.0604779 C7.61590828,22.5387706 7.00431787,22.7026457 6.52602525,22.4265033 C6.04773263,22.150361 5.88385747,21.5387706 6.15999985,21.0604779 L7.14319965,19.3575259 Z M15.1367085,20.3616573 C15.756345,20.0972995 16.3358198,19.7569961 16.8634386,19.3524415 L17.8320512,21.0301278 C18.1081936,21.5084204 17.9443184,22.1200108 17.4660258,22.3961532 C16.9877332,22.6722956 16.3761428,22.5084204 16.1000004,22.0301278 L15.1367085,20.3616573 Z"
                fill="#000000"></path>
              <path
                d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z M19.068812,3.25407593 L20.8181344,5.00339833 C21.4039208,5.58918477 21.4039208,6.53893224 20.8181344,7.12471868 C20.2323479,7.71050512 19.2826005,7.71050512 18.696814,7.12471868 L16.9474916,5.37539627 C16.3617052,4.78960984 16.3617052,3.83986237 16.9474916,3.25407593 C17.5332781,2.66828949 18.4830255,2.66828949 19.068812,3.25407593 Z M5.29862906,2.88207799 C5.8844155,2.29629155 6.83416297,2.29629155 7.41994941,2.88207799 C8.00573585,3.46786443 8.00573585,4.4176119 7.41994941,5.00339833 L5.29862906,7.12471868 C4.71284263,7.71050512 3.76309516,7.71050512 3.17730872,7.12471868 C2.59152228,6.53893224 2.59152228,5.58918477 3.17730872,5.00339833 L5.29862906,2.88207799 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="team|team">
              Metodologias ágeis
            </h5>
            <p i18n="team|team">
              Dobro do trabalho em metade do tempo. Nosso time trabalha de forma organizada e eficiente, sempre focando
              o resultado final.
            </p>
          </div>
        </div>
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/home/stairs.svg">
            <title>Icon For Stairs</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M21,5.5 L21,17.5 C21,18.3284271 20.3284271,19 19.5,19 L4.5,19 C3.67157288,19 3,18.3284271 3,17.5 L3,14.5 C3,13.6715729 3.67157288,13 4.5,13 L9,13 L9,9.5 C9,8.67157288 9.67157288,8 10.5,8 L15,8 L15,5.5 C15,4.67157288 15.6715729,4 16.5,4 L19.5,4 C20.3284271,4 21,4.67157288 21,5.5 Z"
                fill="#000000" fill-rule="nonzero"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="team|team">
              Crescimento rápido
            </h5>
            <p i18n="team|team">
              Não esperamos ficar pra trás. Nós estudamos, testamos, e colocamos em prática novas tecnologias de maneira
              ágil.
            </p>
          </div>
        </div>
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/home/door-open.svg">
            <title>Icon For Door-open</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <polygon fill="#000000" fill-rule="nonzero" opacity="0.3"
                points="7 4.89473684 7 21 5 21 5 3 11 3 11 4.89473684"></polygon>
              <path
                d="M10.1782982,2.24743315 L18.1782982,3.6970464 C18.6540619,3.78325557 19,4.19751166 19,4.68102291 L19,19.3190064 C19,19.8025177 18.6540619,20.2167738 18.1782982,20.3029829 L10.1782982,21.7525962 C9.63486295,21.8510675 9.11449486,21.4903531 9.0160235,20.9469179 C9.00536265,20.8880837 9,20.8284119 9,20.7686197 L9,3.23140966 C9,2.67912491 9.44771525,2.23140966 10,2.23140966 C10.0597922,2.23140966 10.119464,2.2367723 10.1782982,2.24743315 Z M11.9166667,12.9060229 C12.6070226,12.9060229 13.1666667,12.2975724 13.1666667,11.5470105 C13.1666667,10.7964487 12.6070226,10.1879981 11.9166667,10.1879981 C11.2263107,10.1879981 10.6666667,10.7964487 10.6666667,11.5470105 C10.6666667,12.2975724 11.2263107,12.9060229 11.9166667,12.9060229 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="team|team">Ambiente flexivel</h5>
            <p i18n="team|team">
              Nem todo mundo trabalha da mesma maneira. Nosso time tem a liberdade para escolher como preferem
              trabalhar, nosso comprometimento é com os resultados.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- TEAM -->
  <section>
    <div class="container">
      <div class="row mb-4 aos-init aos-animate" data-aos="fade-up">
        <div class="col">
          <h2 i18n="team|team">
            Time principal
          </h2>
        </div>
      </div>

      <div class="row mb-3">

        <div class="col-xl-4 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <span class="badge badge-dark badge-top" i18n="team|team">
              Co-Fundador
            </span>
            <img src="assets/img/team/guga.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Gustavo
              Bittar
            </h5>
            <span i18n="team|team">
              COO
            </span>

          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <span class="badge badge-dark badge-top" i18n="team|team">
              Co-Fundador
            </span>
            <img src="assets/img/team/bruno.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Bruno Campanhã
            </h5>
            <span>
              CPO
            </span>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <span class="badge badge-dark badge-top" i18n="team|team">
              Co-Fundador
            </span>
            <img src="assets/img/team/octhavio.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Octhavio Martins
            </h5>
            <span i18n="team|team">
              CTO
            </span>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/team/victor.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Victor
              <br>
              Silva
            </h5>
            <span i18n="team|team">
              Gestão de Projetos
            </span>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/team/andrews.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Andrews
              <br>
              Alves
            </h5>
            <span i18n="team|team">
              Líder de Mobile
            </span>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/team/paulo.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Paulo
              <br>
              Marinho
            </h5>
            <span i18n="team|team">
              Líder de Backend
            </span>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/team/vinnie.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Vinicius
              <br>
              Alves
            </h5>
            <span i18n="team|team">
              Líder de Web
            </span>
          </div>
        </div>
        <!-- <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/team/filipe.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Filipe
              <br>
              Santos
            </h5>
            <span>
              Tech Lead
            </span>
          </div>
        </div> -->
        <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/team/lucas.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Lucas
              <br>
              Farah
            </h5>
            <span i18n="team|team">
              Tech Lead
            </span>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/team/guilherme.png" class="mb-3">
            <h5 class="mb-0 text-center">
              Guilherme
              <br>
              Martins
            </h5>
            <span i18n="team|team">
              UI/UX
            </span>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/team/vinicius.jpg" class="mb-3">
            <h5 class="mb-0 text-center">
              Vinicius
              <br>
              Moreira
            </h5>
            <span i18n="team|team">
              Qualidade
            </span>
          </div>
        </div>
        <!-- <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/maquina.jpg"  class="mb-3">
            <h5 class="mb-0 text-center" i18n="team|team">
              Maquina
              de café
            </h5>
            <span i18n="team|team">
              CEO
            </span>
          </div>
        </div>  -->
        <!-- 
        <div class="col-xl-3 col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="NaN">
          <div class="card card-lg card-body align-items-center">
            <img src="assets/img/renato.jpg"  class="mb-3">
            <h5 class="mb-0 text-center">
              Renato
              <br>
              Alves
            </h5>
            <span>
              Marketing
            </span>
          </div>
        </div>-->

      </div>
      <div class="row">
        <div class="col">
          <span i18n="team|team">
            Quer trabalhar com a gente? Envie seu currículo para
          </span>
          <a href="mailto:contato@phurshell.com">
            contato@phurshell.com
          </a>
        </div>
      </div>
    </div>
  </section>

  <!-- CONTACT CTA -->
  <app-contact-cta></app-contact-cta>

</div>
<app-footer></app-footer>