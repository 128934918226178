<app-navbar></app-navbar>
<div class="main-content pt-6">

  <!-- JUMBOTRON -->
  <section class="o-hidden min-vh-100 d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center min-vh-50">
        <div class="col-lg-8 mx-2 mb-4 mb-lg-0">
          <h1 class="display-4 mb-0" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <span i18n="home|jumbotron - title">Transformamos seu negócio através de </span><br>
            <div class="highlight">
              <span class="display-4 typed-element text-primary"></span>
              <span>&nbsp;</span>

            </div>
          </h1>
          <!-- <h1 class="display-4 mb-0" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <span i18n="home|jumbotron - title">Através de </span><br>
            <div class="highlight">
              <span class="display-4 typed-element text-primary"></span>
              <span>&nbsp;</span>

            </div><br>
            <span i18n="home|jumbotron - title">transformamos seu negócio.</span>

          </h1> -->
            <p class="lead my-4" data-aos="fade-up" data-aos-delay="120" data-aos-duration="1000" i18n="home|jumbotron - description">
              Somos uma agência especializada em desenvolvimento de aplicativos móveis, plataformas web e produtos digitais.
            </p>
        </div>
      </div>
    </div>
  </section>



  <!-- PILARES -->
  <!-- <section>
    <div class="container">
      <div class="row justify-content-center text-center mb-6">
        <div class="col-xl-8 col-lg-9">
          <h2 class="display-4 mx-xl-6">Nossos pilares</h2>
        
        </div>
      
      </div>
      <div class="row text-center">
        <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <img src="assets/img/pillars/pillar1.svg" alt="Image" class="mb-4 mt-1">
          <h5>Design inteligente</h5>
          <p class="px-xl-4">Entendemos as necessidades do usuário final e atuamos na idealização de um produto relevante e engajador.</p>
        </div>
        <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <img src="assets/img/pillars/pillar2.svg" alt="Image" class="mb-4 mt-2">
          <h5>Tecnologia de ponta</h5>
          <p class="px-xl-4">Buscamos as melhores tecnologias para atender melhor seu cliente, com segurança e privacidade (LGPD).</p>
        </div>
        <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
          <img src="assets/img/pillars/pillar3.svg" alt="Image" class="mb-4">
          <h5>Squads de alta performance</h5>
          <p class="px-xl-4">Times multidisciplinares ágeis, focados no sucesso do cliente.</p>
        </div>
        <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
          <img src="assets/img/pillars/pillar4.svg" alt="Image" class="mb-4">
          <h5>Atendimento VIP</h5>
          <p class="px-xl-4">Atendimento personalizado do briefing à manutenção do projeto.</p>
        </div>
      </div>
    </div>
  </section> -->



  <!-- SERVICES -->
  <section class="mt-0">
    <div class="container">
      <div class="row mb-4">
        <div class="col">
          <h3 class="h1" i18n="home|services - title">
            O que fazemos?
          </h3>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col">
          <div class="row">
            <div class="col-xl-3 d-flex aos-animate" data-aos="fade-up" data-aos-delay="200">
              <a routerLink="{{ PathRoutes.Services }}" fragment="apoio-continuo" [queryParams]="{bbb: 'aaa'}"
                class="card card-icon-3 card-body justify-content-between hover-shadow-3d">
                <div class="icon-round mb-3 mb-md-4 bg-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/design/select.svg">
                    <title>Icon For Select</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon opacity="0" points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
                        <path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
                    </g>
                </svg>

                </div>
                <div>
                  <h5 i18n="home|services - aplication title">Apoio contínuo</h5>
                  <p class="xl-4" i18n="home|services - aplication description">
                    Disponibilização de um squad ágil para desenvolver suas demandas.
                  </p>
                  <p class="mb-0 text-primary font-weight-bold" i18n="home|services - aplication link">
                    Saiba mais
                  </p>
                </div>
              </a>
            </div>

            <div class="col-xl-3 d-flex aos-animate" data-aos="fade-up" data-aos-delay="200">
              <a routerLink="{{ PathRoutes.Services }}" fragment="apps"
                class="card card-icon-3 card-body justify-content-between hover-shadow-3d">
                <div class="icon-round mb-3 mb-md-4 bg-primary">

                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary"
                  data-src="assets/img/icons/theme/devices/iphone-x.svg">
                  <title>Icon For iPhone-X</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z"
                      fill="#000000" opacity="0.3"></path>
                    <path
                      d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z"
                      fill="#000000" fill-rule="nonzero"></path>
                  </g>
                </svg>
                  
                </div>
                <div>
                  <h5 i18n="home|services - web title">Aplicativos móveis e web</h5>
                  <p class="xl-4" i18n="home|services - web description">
                    Desenvolvemos aplicativos utilizando as melhores tecnologias do mercado.
                  </p>
                  <p class="mb-0 text-primary font-weight-bold" i18n="home|services - web title">
                    Saiba mais
                  </p>
                </div>
              </a>
            </div>

            <div class="col-xl-3 d-flex aos-animate" data-aos="fade-up" data-aos-delay="200">
              <a routerLink="{{ PathRoutes.Services }}"  fragment="mvp"
                class="card card-icon-3 card-body justify-content-between hover-shadow-3d">
                <div class="icon-round mb-3 mb-md-4 bg-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary"
                    data-src="assets/img/icons/theme/design/pen-ruler.svg">
                    <title>Icon For Pen&amp;ruller</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                        fill="#000000" opacity="0.3"></path>
                      <path
                        d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                        fill="#000000"></path>
                    </g>
                  </svg>
                </div>
                <div>
                  <h5 i18n="home|services - design title">MVP e Prova de Conceito</h5>
                  <p class="xl-4" i18n="home|services - design description">
                    Te ajudamos a validar seu MVP e viabilizar seu projeto.
                  </p>
                  <p class="mb-0 text-primary font-weight-bold" i18n="home|services - design link">
                    Saiba mais
                  </p>
                </div>
              </a>
            </div>

            <div class="col-xl-3 d-flex aos-animate" data-aos="fade-up" data-aos-delay="200">
              <a routerLink="{{ PathRoutes.Services }}"  fragment="mentoria"
                class="card card-icon-3 card-body justify-content-between hover-shadow-3d">
                <div class="icon-round mb-3 mb-md-4 bg-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary" data-src="assets/img/icons/theme/communication/group.svg">
                    <title>Icon For Group</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
                        <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                        <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path>
                    </g>
                </svg>
                </div>
                <div>
                  <h5 i18n="home|services - design title">Mentoria em tecnologia</h5>
                  <p class="xl-4" i18n="home|services - design description">
                    Te ajudamos a digitalizar sua empresa e trazer tecnologia pro seu produto.
                  </p>
                  <p class="mb-0 text-primary font-weight-bold" i18n="home|services - design link">
                    Saiba mais
                  </p>
                </div>
              </a>
            </div>

          </div>

        </div>
      </div>
    </div>
  </section>

  <div class="row justify-content-center text-center mb-6">
    <div class="col-xl-8 col-lg-9">
      <h2 class="display-4 mx-xl-6">Onde a Phurshell já fez a diferença?</h2>
      <!-- <p class="lead">
        Na Phurshell prezamos em usufruir de nossos valores para impulsionar seu negócio.
      </p> -->
    </div>
  </div>

  <!-- PSIAPP -->
  <section class="pt-0 pb-2">
    <div class="card bg-psiapp b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_psiapp.svg" class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0" i18n="home|psiapp - app name">
                    Psiapp
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <h3 class="h2" i18n="home|psiapp - title">
                  O aplicativo que conecta psicólogos e pacientes.
                </h3>
                <p class="lead mb-4" i18n="home|psiapp - description">
                  A praticidade chegou pra quem precisa de um atendimento psicológico eficiente e rápido.
                </p>
                
                  <a href="https://apps.apple.com/ba/app/psiapp/id1597414650"
                  target="_blank" class="btn btn-dark text-white mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.psi.psiapp.androidapp" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
              </a>
                
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                  <img src="assets/img/screenshot_psiapp.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- BUYAPP -->
  <section class="pt-0 pb-2">
    <div class="card bg-autoday b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position">
                  <img src="assets/img/screenshot_autoday.png" alt="Image">
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_autoday.svg"  class="mr-3 rounded-icon shadow-lg" alt="Image"
                  width="75px">
                <div>
                  <h1 class="mb-0" i18n="home|autoday - app name">
                    Autoday
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <h3 class="h2" i18n="home|buyapp - title">
                  O melhor seguro para seu carro
                </h3>
                <p class="lead mb-4" i18n="home|buyapp - description">
                  O aplicativo que ajuda você a proteger seu carro com um seguro intermitente revolucionário
                </p>
                <a href="https://apps.apple.com/br/app/autoday/id1589126844"
                  target="_blank" class="btn btn-dark text-white mr-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                    data-src="assets/img/icons/social/apple.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Apple icon</title>
                    <path
                      d="M7.078 23.55c-.473-.316-.893-.703-1.244-1.15-.383-.463-.738-.95-1.064-1.454-.766-1.12-1.365-2.345-1.78-3.636-.5-1.502-.743-2.94-.743-4.347 0-1.57.34-2.94 1.002-4.09.49-.9 1.22-1.653 2.1-2.182.85-.53 1.84-.82 2.84-.84.35 0 .73.05 1.13.15.29.08.64.21 1.07.37.55.21.85.34.95.37.32.12.59.17.8.17.16 0 .39-.05.645-.13.145-.05.42-.14.81-.31.386-.14.692-.26.935-.35.37-.11.728-.21 1.05-.26.39-.06.777-.08 1.148-.05.71.05 1.36.2 1.94.42 1.02.41 1.843 1.05 2.457 1.96-.26.16-.5.346-.725.55-.487.43-.9.94-1.23 1.505-.43.77-.65 1.64-.644 2.52.015 1.083.29 2.035.84 2.86.387.6.904 1.114 1.534 1.536.31.21.582.355.84.45-.12.375-.252.74-.405 1.1-.347.807-.76 1.58-1.25 2.31-.432.63-.772 1.1-1.03 1.41-.402.48-.79.84-1.18 1.097-.43.285-.935.436-1.452.436-.35.015-.7-.03-1.034-.127-.29-.095-.576-.202-.856-.323-.293-.134-.596-.248-.905-.34-.38-.1-.77-.148-1.164-.147-.4 0-.79.05-1.16.145-.31.088-.61.196-.907.325-.42.175-.695.29-.855.34-.324.096-.656.154-.99.175-.52 0-1.004-.15-1.486-.45zm6.854-18.46c-.68.34-1.326.484-1.973.436-.1-.646 0-1.31.27-2.037.24-.62.56-1.18 1-1.68.46-.52 1.01-.95 1.63-1.26.66-.34 1.29-.52 1.89-.55.08.68 0 1.35-.25 2.07-.228.64-.568 1.23-1 1.76-.435.52-.975.95-1.586 1.26z">
                    </path>
                  </svg>
                  <span>App Store</span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.ph.autoday" target="_blank"
                class="btn btn-dark text-white mr-2 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" class="injected-svg icon"
                  data-src="assets/img/icons/social/googleplay.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Google Play icon</title>
                  <path
                    d="M1.22 0c-.03.093-.06.185-.06.308v23.229c0 .217.061.34.184.463l11.415-12.093L1.22 0zm12.309 12.708l2.951 3.045-4.213 2.4s-5.355 3.044-8.308 4.739l9.57-10.184zm.801-.831l3.166 3.292c.496-.276 4.371-2.492 4.924-2.8.584-.338.525-.8.029-1.046-.459-.255-4.334-2.475-4.92-2.835l-3.203 3.392.004-.003zm-.803-.8l2.984-3.169-4.259-2.431S5.309 1.505 2.999.179l10.53 10.898h-.002z">
                  </path>
                </svg>
                <span>Google Play</span>
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- BLUU / IMG TO RIGHT -->
  <section class="pt-0 pb-2">
    <div class="card bg-bluu b-hidden m-3 m-md-5 py-6" data-aos="fade-up">
      <div class="d-flex align-items-center text-light p-sm-3 p-md-5">
        <div class="container">
          <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 px-md-6 px-lg-0" data-aos="fade-right">
              <div class="d-flex align-items-center mb-3">
                <img src="assets/img/icon_bluu.svg" class="mr-3 rounded-icon shadow-lg" alt="Image" width="75px">
                <div>
                  <h1 class="mb-0">
                    Bluu
                  </h1>
                </div>
              </div>
              <div class="mt-3">
                <h3 class="h2" i18n="home|buyapp - title">
                  Cuidando do seu pet
                </h3>
                <p class="lead mb-4" i18n="home|buyapp - description">
                  O aplicativo que ajuda tutores a cuidar da saúde e do comportamento do seu pet.
                </p>
              
              
                <div class="opacity-30">
                  <button class="btn btn-dark text-white mr-2 mb-2 disabled">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary" data-src="assets/img/icons/theme/tools/hummer.svg">
                      <title>Icon For Hummer</title>
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                          <path d="M18.4246212,12.6464466 L21.2530483,9.81801948 C21.4483105,9.62275734 21.764893,9.62275734 21.9601551,9.81801948 L22.6672619,10.5251263 C22.862524,10.7203884 22.862524,11.0369709 22.6672619,11.232233 L19.8388348,14.0606602 C19.6435726,14.2559223 19.3269901,14.2559223 19.131728,14.0606602 L18.4246212,13.3535534 C18.2293591,13.1582912 18.2293591,12.8417088 18.4246212,12.6464466 Z M3.22182541,17.9497475 L13.1213203,8.05025253 C13.5118446,7.65972824 14.1450096,7.65972824 14.5355339,8.05025253 L15.9497475,9.46446609 C16.3402718,9.85499039 16.3402718,10.4881554 15.9497475,10.8786797 L6.05025253,20.7781746 C5.65972824,21.1686989 5.02656326,21.1686989 4.63603897,20.7781746 L3.22182541,19.363961 C2.83130112,18.9734367 2.83130112,18.3402718 3.22182541,17.9497475 Z" fill="#000000" opacity="0.3"></path>
                          <path d="M12.3890873,1.28248558 L12.3890873,1.28248558 C15.150511,1.28248558 17.3890873,3.52106183 17.3890873,6.28248558 L17.3890873,10.7824856 C17.3890873,11.058628 17.1652297,11.2824856 16.8890873,11.2824856 L12.8890873,11.2824856 C12.6129449,11.2824856 12.3890873,11.058628 12.3890873,10.7824856 L12.3890873,1.28248558 Z" fill="#000000" transform="translate(14.889087, 6.282486) rotate(-45.000000) translate(-14.889087, -6.282486) "></path>
                      </g>
                  </svg>
                    <span i18n="portfolio|">
                      Em construção
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="100">
              <div class="device-position device-on-right">
                <img src="assets/img/screenshot_bluu.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>




  <!-- CUSTOMERS -->
  <section>
    <div class="container">
      <div class="row justify-content-between o-hidden">
        <div class="col-lg-6 col-sm-12">
          <h2 class="h1" i18n="home|customers - title">
            Vamos conversar e transformar o seu negócio.
          </h2>
          <p class="lead" i18n="home|customers - description">
            Já ajudamos centenas de empresas a inovar e construir seus produtos digitais.
          </p>
          <a class="lead" routerLink="{{ PathRoutes.Portfolio }}">
            <span i18n="home|customers - link">Conheça nosso portfolio</span>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
              height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary"
              data-src="assets/img/icons/theme/code/right-circle.svg">
              <title>Icon For Right-circle</title>
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                <path
                  d="M7.96323356,15.1775211 C7.62849853,15.5122561 7.08578582,15.5122561 6.75105079,15.1775211 C6.41631576,14.842786 6.41631576,14.3000733 6.75105079,13.9653383 L11.8939067,8.82248234 C12.2184029,8.49798619 12.7409054,8.4866328 13.0791905,8.79672747 L18.2220465,13.5110121 C18.5710056,13.8308912 18.5945795,14.3730917 18.2747004,14.7220508 C17.9548212,15.0710098 17.4126207,15.0945838 17.0636617,14.7747046 L12.5257773,10.6149773 L7.96323356,15.1775211 Z"
                  fill="#000000" fill-rule="nonzero"
                  transform="translate(12.500001, 12.000001) rotate(-270.000000) translate(-12.500001, -12.000001) ">
                </path>
              </g>
            </svg>
          </a>
        </div>
        <div class="col-lg-5 col-sm-12 aos-init aos-animate mt-xs-5" data-aos="fade-left">
          <ul class="d-flex flex-wrap justify-content-start list-unstyled">
            <li class="mx-4 mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
              <img src="assets/img/logo-partners-2.svg" alt="american airlines" height="55">
            </li>
            <li class="mx-4 mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
              <img src="assets/img/logo-partners-1.svg" alt="american airlines" height="55">
            </li>
            <li class="mx-4 mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
              <img src="assets/img/logo-partners-6.svg" alt="american airlines" height="55">
            </li>
            <li class="mx-4 mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
              <img src="assets/img/logo-partners-4.svg" alt="american airlines" height="55">
            </li>
            <li class="mx-4 mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
              <img src="assets/img/logo-partners-5.svg" alt="american airlines" height="55">
            </li>
            <li class="mx-4 mb-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
              <img src="assets/img/logo-partners-3.svg" alt="american airlines" height="60">
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  

  <!-- HOW WE WORK -->
<!--   
  <section class="pt-0">
    <div class="container">
      <div class="row mb-4">
        <div class="col">
          <h2 i18n="home|how we work - title">
            Como podemos te ajudar?
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary"
            data-src="assets/img/icons/theme/home/bulb-1.svg">
            <title>Icon For Bulb#1</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" opacity="0.3" cx="12" cy="9" r="8"></circle>
              <path
                d="M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z"
                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
              <path
                d="M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="home|how we work - idea title">Descoberta</h5>
            <div i18n="home|how we work - idea description">
              Entendemos as dores do seu negócio, suas ideias de inovação e ajudamos a formular soluções viáveis.
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/general/binocular.svg">
            <title>Icon For Binocular</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M12.8434797,16 L11.1565203,16 L10.9852159,16.6393167 C10.3352654,19.064965 7.84199997,20.5044524 5.41635172,19.8545019 C2.99070348,19.2045514 1.55121603,16.711286 2.20116652,14.2856378 L3.92086709,7.86762789 C4.57081758,5.44197964 7.06408298,4.00249219 9.48973122,4.65244268 C10.5421727,4.93444352 11.4089671,5.56345262 12,6.38338695 C12.5910329,5.56345262 13.4578273,4.93444352 14.5102688,4.65244268 C16.935917,4.00249219 19.4291824,5.44197964 20.0791329,7.86762789 L21.7988335,14.2856378 C22.448784,16.711286 21.0092965,19.2045514 18.5836483,19.8545019 C16.158,20.5044524 13.6647346,19.064965 13.0147841,16.6393167 L12.8434797,16 Z M17.4563502,18.1051865 C18.9630797,18.1051865 20.1845253,16.8377967 20.1845253,15.2743923 C20.1845253,13.7109878 18.9630797,12.4435981 17.4563502,12.4435981 C15.9496207,12.4435981 14.7281751,13.7109878 14.7281751,15.2743923 C14.7281751,16.8377967 15.9496207,18.1051865 17.4563502,18.1051865 Z M6.54364977,18.1051865 C8.05037928,18.1051865 9.27182488,16.8377967 9.27182488,15.2743923 C9.27182488,13.7109878 8.05037928,12.4435981 6.54364977,12.4435981 C5.03692026,12.4435981 3.81547465,13.7109878 3.81547465,15.2743923 C3.81547465,16.8377967 5.03692026,18.1051865 6.54364977,18.1051865 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="home|how we work - discovery title">Exploramos o mercado</h5>
            <div i18n="home|how we work - discovery description">
              Após definirmos o escopo, te ajudamos a entender seus concorrentes e o seu cliente final.
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary"
            data-src="assets/img/icons/theme/design/pen-ruler.svg">
            <title>Icon For Pen&amp;ruller</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="home|how we work - prototype title">Prova de Conceito</h5>
            <div i18n="home|how we work - prototype description">
              Rapidamente desenvolvemos protótipos, MVPs e provas de conceito para te ajudar a validar os seus produtos.
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/devices/display-1.svg">
            <title>Icon For Display#1</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M11,20 L11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 L13,20 L15.5,20 C15.7761424,20 16,20.2238576 16,20.5 C16,20.7761424 15.7761424,21 15.5,21 L8.5,21 C8.22385763,21 8,20.7761424 8,20.5 C8,20.2238576 8.22385763,20 8.5,20 L11,20 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,16 C22,16.5522847 21.5522847,17 21,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M4.5,8 C4.22385763,8 4,8.22385763 4,8.5 C4,8.77614237 4.22385763,9 4.5,9 L13.5,9 C13.7761424,9 14,8.77614237 14,8.5 C14,8.22385763 13.7761424,8 13.5,8 L4.5,8 Z M4.5,10 C4.22385763,10 4,10.2238576 4,10.5 C4,10.7761424 4.22385763,11 4.5,11 L7.5,11 C7.77614237,11 8,10.7761424 8,10.5 C8,10.2238576 7.77614237,10 7.5,10 L4.5,10 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="home|how we work - development title">Desenvolvimento</h5>
            <div i18n="home|how we work - development description">
              Depois de ter um MVP validado, desenvolvemos seu produto final através de nossos times multidisciplinares de alta performance, utilizando as melhores tecnologias do mercado, e sempre focados em segurança.
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/communication/clipboard-check.svg">
            <title>Icon For Clipboard-check</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                fill="#000000"></path>
              <path
                d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="home|how we work - publish title">Teste e qualidade</h5>
            <div i18n="home|how we work - publish description">
              Temos uma equipe especializada em testes de todos os tipos para garantir a qualidade do seu produto. 
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 d-flex mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary"
            data-src="assets/img/icons/theme/tools/tools.svg">
            <title>Icon For Tools</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M15.9497475,3.80761184 L13.0246125,6.73274681 C12.2435639,7.51379539 12.2435639,8.78012535 13.0246125,9.56117394 L14.4388261,10.9753875 C15.2198746,11.7564361 16.4862046,11.7564361 17.2672532,10.9753875 L20.1923882,8.05025253 C20.7341101,10.0447871 20.2295941,12.2556873 18.674559,13.8107223 C16.8453326,15.6399488 14.1085592,16.0155296 11.8839934,14.9444337 L6.75735931,20.0710678 C5.97631073,20.8521164 4.70998077,20.8521164 3.92893219,20.0710678 C3.1478836,19.2900192 3.1478836,18.0236893 3.92893219,17.2426407 L9.05556629,12.1160066 C7.98447038,9.89144078 8.36005124,7.15466739 10.1892777,5.32544095 C11.7443127,3.77040588 13.9552129,3.26588995 15.9497475,3.80761184 Z"
                fill="#000000"></path>
              <path
                d="M16.6568542,5.92893219 L18.0710678,7.34314575 C18.4615921,7.73367004 18.4615921,8.36683502 18.0710678,8.75735931 L16.6913928,10.1370344 C16.3008685,10.5275587 15.6677035,10.5275587 15.2771792,10.1370344 L13.8629656,8.7228208 C13.4724413,8.33229651 13.4724413,7.69913153 13.8629656,7.30860724 L15.2426407,5.92893219 C15.633165,5.5384079 16.26633,5.5384079 16.6568542,5.92893219 Z"
                fill="#000000" opacity="0.3"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="home|how we work - maintenance title">Apoio contínuo</h5>
            <div i18n="home|how we work - maintenance description">
              Através de rotinas de testes e desenvolvimento de novas funcionalidades, periodicamente uma nova versão de seu projeto é publicada.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- TEAM -->
  <section>
    <div class="container aos-init aos-animate" data-aos="fade-up">
      <div class="row align-items-center justify-content-around">
        <div class="col-md-5 col-xl-6 mb-4 mb-md-0">
          <img src="assets/img/img-team.jpg" alt="Image" class="rounded shadow-3d">
        </div>
        <div class="col-md-7 col-xl-6">
          <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-10">
              <div class="my-3">
                <span class="h1" i18n="home|team - title">
                  Um time que faz acontecer!
                </span>
              </div>
              <p class="lead" i18n="home|team - description1">
                Somos uma equipe de entusiastas em tecnologia sempre focada em trazer o que há de mais novo no mercado para nossos parceiros.
              </p>
              <p class="lead" i18n="home|team - description2">
                Gostamos do que fazemos e nos divertimos enquanto estamos trabalhando.
              </p>
              <a class="lead" routerLink="{{ PathRoutes.Team }}">
                <span i18n="home|team - link">
                  Conheça nosso time
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon bg-primary"
                  data-src="assets/img/icons/theme/code/right-circle.svg">
                  <title>Icon For Right-circle</title>
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                    <path
                      d="M7.96323356,15.1775211 C7.62849853,15.5122561 7.08578582,15.5122561 6.75105079,15.1775211 C6.41631576,14.842786 6.41631576,14.3000733 6.75105079,13.9653383 L11.8939067,8.82248234 C12.2184029,8.49798619 12.7409054,8.4866328 13.0791905,8.79672747 L18.2220465,13.5110121 C18.5710056,13.8308912 18.5945795,14.3730917 18.2747004,14.7220508 C17.9548212,15.0710098 17.4126207,15.0945838 17.0636617,14.7747046 L12.5257773,10.6149773 L7.96323356,15.1775211 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(12.500001, 12.000001) rotate(-270.000000) translate(-12.500001, -12.000001) ">
                    </path>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- CONTACT CTA -->
  <app-contact-cta></app-contact-cta>

</div>
<app-footer></app-footer>