import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PathRoutes } from '../../routes/routersUrl';

import { LOCALE_ID, Inject } from '@angular/core';


declare var $:any

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  PathRoutes = PathRoutes


  language


  constructor (
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(LOCALE_ID) protected localeId: string
  ) { 

    this.language = localeId
    
  }

  ngOnInit() {
    $("#modal").modal("hide");
  }

}
