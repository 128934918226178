 <!-- HOW MUCH CTA -->
 <section class="min-vh-70 d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-center text-center aos-init aos-animate" data-aos="fade-up">
        <div class="col-lg-6 col-md-8">
          <h1 i18n="contact cta|contact">
            Peça um orçamento agora!
          </h1>
          <div class="d-sm-flex justify-content-center my-2 my-md-4">
            <a routerLink="{{ PathRoutes.Calculator }}" class="btn btn-lg btn-outline-primary mx-sm-2 mb-3 mb-sm-0">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
                data-src="assets/img/icons/theme/shopping/calculator.svg">
                <title>Icon For Calculator</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                  <rect fill="#000000" opacity="0.3" x="7" y="4" width="10" height="4"></rect>
                  <path
                    d="M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 Z M8,16 C8.55228475,16 9,15.5522847 9,15 C9,14.4477153 8.55228475,14 8,14 C7.44771525,14 7,14.4477153 7,15 C7,15.5522847 7.44771525,16 8,16 Z M12,12 C12.5522847,12 13,11.5522847 13,11 C13,10.4477153 12.5522847,10 12,10 C11.4477153,10 11,10.4477153 11,11 C11,11.5522847 11.4477153,12 12,12 Z M12,16 C12.5522847,16 13,15.5522847 13,15 C13,14.4477153 12.5522847,14 12,14 C11.4477153,14 11,14.4477153 11,15 C11,15.5522847 11.4477153,16 12,16 Z M16,12 C16.5522847,12 17,11.5522847 17,11 C17,10.4477153 16.5522847,10 16,10 C15.4477153,10 15,10.4477153 15,11 C15,11.5522847 15.4477153,12 16,12 Z M16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.4477153,14 15,14.4477153 15,15 C15,15.5522847 15.4477153,16 16,16 Z M16,20 C16.5522847,20 17,19.5522847 17,19 C17,18.4477153 16.5522847,18 16,18 C15.4477153,18 15,18.4477153 15,19 C15,19.5522847 15.4477153,20 16,20 Z M8,18 C7.44771525,18 7,18.4477153 7,19 C7,19.5522847 7.44771525,20 8,20 L12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 L8,18 Z M7,4 L7,8 L17,8 L17,4 L7,4 Z"
                    fill="#000000"></path>
                </g>
              </svg>
              <span i18n="contact cta|contact">
                Quanto custa um aplicativo?
              </span>
            </a>
            <a href="" class="btn btn-lg btn-primary mx-sm-2 mb-3 mb-sm-0" routerLink="{{ PathRoutes.Contact }}">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
                data-src="assets/img/icons/theme/communication/sending-mail.svg">
                <title>Icon For Sending mail</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z"
                    fill="#000000" opacity="0.3"></path>
                  <path
                    d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z"
                    fill="#000000"></path>
                </g>
              </svg>
              <span i18n="contact cta|contact">
                Entrar em contato
              </span>
            </a>
          </div>
          <div class="mx-lg-5 text-small text-muted" i18n="contact cta|contact">
            Temos um time de profissionais engajados que te ajudarão a encontrar a melhor solução pra você ou sua
            empresa.
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- CONTACT EXTRAS -->
  <section class="mb-6 pt-0">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/map/marker-1.svg">
            <title>Icon For Marker#1</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z"
                fill="#000000" fill-rule="nonzero"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="contact cta|contact">
              Conheça nosso escritório
            </h5>
            <p i18n="contact cta|contact">
              Nossa escritório está há três quadras da Avenida Paulista. Estaremos sempre com as portas abertas pra
              tomar
              um café e ouvir boas ideias.
            </p>
            <a href="https://maps.google.com/?q=Phurshell | Design e desenvolvimento de apps - Rua Haddock Lobo, sala 609 - Cerqueira César, São Paulo - SP" i18n="contact cta|contact">
              Haddock Lobo 131, sala 609 - SP
            </a>
          </div>
        </div>
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-lg bg-primary"
            data-src="assets/img/icons/theme/communication/sending-mail.svg">
            <title>Icon For Sending mail</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z"
                fill="#000000" opacity="0.3"></path>
              <path
                d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z"
                fill="#000000"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="contact cta|contact">
              Nos envie um email
            </h5>
            <p i18n="contact cta|contact">
              Se você precisar, também pode nos enviar um email contando sua ideia diretamente para nosso email de
              relacionamento.
            </p>
            <a href="mailto:contato@phurshell.com">contato@phurshell.com</a>
          </div>
        </div>
        <div class="col-12 col-md d-flex mb-3 mb-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="injected-svg icon icon-md bg-primary"
            data-src="assets/img/icons/theme/communication/active-call.svg">
            <title>Icon For Active-call</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect opacity="0" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M13.0799676,14.7839934 L15.2839934,12.5799676 C15.8927139,11.9712471 16.0436229,11.0413042 15.6586342,10.2713269 L15.5337539,10.0215663 C15.1487653,9.25158901 15.2996742,8.3216461 15.9083948,7.71292558 L18.6411989,4.98012149 C18.836461,4.78485934 19.1530435,4.78485934 19.3483056,4.98012149 C19.3863063,5.01812215 19.4179321,5.06200062 19.4419658,5.11006808 L20.5459415,7.31801948 C21.3904962,9.0071287 21.0594452,11.0471565 19.7240871,12.3825146 L13.7252616,18.3813401 C12.2717221,19.8348796 10.1217008,20.3424308 8.17157288,19.6923882 L5.75709327,18.8875616 C5.49512161,18.8002377 5.35354162,18.5170777 5.4408655,18.2551061 C5.46541191,18.1814669 5.50676633,18.114554 5.56165376,18.0596666 L8.21292558,15.4083948 C8.8216461,14.7996742 9.75158901,14.6487653 10.5215663,15.0337539 L10.7713269,15.1586342 C11.5413042,15.5436229 12.4712471,15.3927139 13.0799676,14.7839934 Z"
                fill="#000000"></path>
              <path
                d="M14.1480759,6.00715131 L13.9566988,7.99797396 C12.4781389,7.8558405 11.0097207,8.36895892 9.93933983,9.43933983 C8.8724631,10.5062166 8.35911588,11.9685602 8.49664195,13.4426352 L6.50528978,13.6284215 C6.31304559,11.5678496 7.03283934,9.51741319 8.52512627,8.02512627 C10.0223249,6.52792766 12.0812426,5.80846733 14.1480759,6.00715131 Z M14.4980938,2.02230302 L14.313049,4.01372424 C11.6618299,3.76737046 9.03000738,4.69181803 7.1109127,6.6109127 C5.19447112,8.52735429 4.26985715,11.1545872 4.51274152,13.802405 L2.52110319,13.985098 C2.22450978,10.7517681 3.35562581,7.53777247 5.69669914,5.19669914 C8.04101739,2.85238089 11.2606138,1.72147333 14.4980938,2.02230302 Z"
                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
            </g>
          </svg>
          <div class="ml-3">
            <h5 i18n="contact cta|contact">
              Pode ligar também
            </h5>
            <p i18n="contact cta|contact">
              Agora se você precisar de um canal direto de comunicação, pode nos ligar. Fique a vontade pra tirar
              qualquer dúvida.
            </p>
            <a href="tel:11991341871">+55 (11) 99134-1871</a>
            <!-- <a href="tel:11975125587">+55 (11) 97512-5587</a> -->
          </div>
        </div>
      </div>
    </div>
  </section>