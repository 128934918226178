import { Component, OnInit } from '@angular/core';
import { PathRoutes } from '../../routes/routersUrl';
import { LOCALE_ID, Inject } from '@angular/core';

@Component({
  selector: 'app-contact-cta',
  templateUrl: './contact-cta.component.html',
  styleUrls: ['./contact-cta.component.scss']
})

export class ContactCtaComponent implements OnInit {

  PathRoutes = PathRoutes
  language

  constructor(@Inject(LOCALE_ID) protected localeId: string) {
    this.language = localeId
   }

  ngOnInit() {
  }

}
