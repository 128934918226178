import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PathRoutes } from '../../routes/routersUrl';

declare var $:any

@Component({
  selector: 'app-modal-navbar-links',
  templateUrl: './modal-navbar-links.component.html',
  styleUrls: ['./modal-navbar-links.component.scss']
})

export class ModalNavbarLinksComponent implements OnInit {

  PathRoutes = PathRoutes

  constructor ( private activatedRoute: ActivatedRoute, private router: Router ) {}

  ngOnInit() {}

}
