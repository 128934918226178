


import { Component, OnInit } from '@angular/core';

import { BlogService } from '../../services/blog.service'

import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-blog-search-bar',
  templateUrl: './blog-search-bar.component.html',
  styleUrls: ['./blog-search-bar.component.scss']
})
export class BlogSearchBarComponent implements OnInit {

 searchQuery

  constructor(private blog : BlogService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

  }


  submit(){

    // console.log('submit search',this.searchQuery)

    this.router.navigate(['/blog/posts/search/' + this.searchQuery]);

  }


}

