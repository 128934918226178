import { Component, OnInit } from '@angular/core';

import { BlogService } from '../../services/blog.service'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.scss']
})
export class BlogPostsComponent implements OnInit {

  title = ''
  category = null
  search = null
  categories
  posts = [ ]


  args = ''

  constructor(private blog : BlogService, private route: ActivatedRoute, private router : Router) { }


  redirect(){
    this.search = this.route.snapshot.paramMap.get('search');
    this.category = this.route.snapshot.paramMap.get('category');
    this.getCategories()

  }


  ngOnInit() {


    this.redirect()
    this.router.events.subscribe((val: any) => {
        if(val instanceof NavigationEnd){
        this.redirect()
        }
     })

  }



  getPosts() {
    this.blog.posts(this.args).subscribe( (res) => {


      res.map((r,i) => {

        this.categories.map((c)=>{
          if(c.id == r.categories[0]) r.category_text = c.name
        })

      })

      console.log(res)



      this.posts = res
      if (this.category) this.title = this.posts[0].category_text


    })
  }

  getCategories() {
    this.blog.categories().subscribe( (res) => {

      this.categories = res

      if(this.search){
        console.log('tem serach')
        this.args = 'search=' + this.search
        this.title = 'Mostrando resultados da pesquisa: \"' + this.search + '\"'
      }
      else if(this.category){
        console.log('tem category')

        this.args = 'categories=' + this.category
      }

      this.getPosts()

    })
  }

}
